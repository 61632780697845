import { Component, OnInit } from '@angular/core';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { RoutingService, TipNotificare, NotificationService, States } from 'core';

@Component({
    selector: 'navbar-notificari', templateUrl: './navbar.notificari.html',
    host: { class: "d-flex flex-column", style: 'height:100%' }, standalone: false
})
export class NavbarNotificari implements OnInit {
    constructor(protected activeOffcanvas: NgbActiveOffcanvas,
        protected notify: NotificationService,
        private routing: RoutingService) { }

    ngOnInit() { }

    protected tip: boolean = false;

    protected getType(item): string {
        if (item.dataVizualizare) { return 'list-group-item-secondary' }
        switch (item.tip || item.idTipNotificare) {
            case 'update': case TipNotificare.update: return 'list-group-item-warning';
            case 'report': case TipNotificare.report: return 'list-group-item-primary';
            case 'error': case TipNotificare.error: return 'list-group-item-danger';
            default: return '';
        }
    }
    protected getIcon(item) {
        switch (item.tip || item.idTipNotificare) {
            case 'report': case TipNotificare.update: return 'fa-print';
            case 'update': case TipNotificare.report: return 'fa-exclamation';
            case 'error': case TipNotificare.error: return 'fa-exclamation-triangle';
            default: return 'fa-info-circle';
        }
    }
    protected setViewed(item, isDismiss?: boolean) {
        if (item == 'all') {
            this.routing.goNext([States.user.notificari]);
            this.activeOffcanvas.close();
        } else {
            this.notify.setViewed(item, isDismiss)
        }
    }
}