import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveOffcanvas, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { States } from 'core';
import { HelpContent } from './help.content';

@Component({
	selector: 'help-container', standalone: true,
	templateUrl: `./navbar.helpContainer.html`,
	host: { 'class': 'h-100 d-flex flex-column' },
	imports: [HelpContent, NgbDropdownModule]
})
export class HelpContainer implements OnInit {
	constructor(private router: Router, protected activeOffcanvas: NgbActiveOffcanvas) { }

	@Input() protected hierarchyId: string;
	@Input() private position: string;
	@Input() private changePos: (position: string) => void;
	@Input() private resize: (size: string) => void;

	ngOnInit(): void { }

	protected width: number;

	protected setSize(size: number) {
		this.width = size;
		this.resize(size === 0 ? '' : `w-${size}`);
	}
	protected change(position: string) {
		this.position = position;
		this.changePos(position);
	}
	protected getPosIcon() {
		switch (this.position) {
			case 'start': return 'fa-arrow-square-left';
			case 'end': return 'fa-arrow-square-right';
			case 'top': return 'fa-arrow-square-up';
			case 'bottom': return 'fa-arrow-square-down';
			default: return 'fa-square';
		}
	}

	protected openSuport() { }
	protected openChat() { }

	protected openHelpEditor() {
		// window.open(this.getUrl(States.help.edit), '_blank');
	}
	protected detachWindow() {
		// const url = this.getUrl(States.help.content, { hierarchyId: this.hierarchyId });
		// const position = `top=${(screen.height / 2)}, left=${(screen.width / 2)}`;
		// const win = window.open(url, '_blank', `resizable=yes, width=1200, height=700, ${position}`);
		// if (win) { this.activeOffcanvas.close() }
	}
	private getUrl(state: string, params?: any) {
		if (!params) { params = {} }
		if (this.hierarchyId) { params.id = window.btoa(this.hierarchyId) }
		return this.router.createUrlTree([state, params]).toString();
	}
}