export const routes = [
  {
    "title": "Administrare sistem",
    "icon": "far fa-user-cog",
    "access": "/10/",
    "child": [
      {
        "title": "Dezvoltare",
        "icon": "fal fa-wrench fa-lg",
        "access": "/10/41/",
        "child": [
          {
            "title": "Administrare resurse",
            "icon": "fal fa-list-alt",
            "access": "/10/41/2/",
            "path": "ng/admin/admin/Resursa",
            "child": [
              {
                "title": "Butoane predefinite",
                "icon": "fal fa-list-ol",
                "access": "/10/41/2/1/1/",
                "path": "ng/admin/admin/Resursa/control"
              }
            ]
          },
          {
            "title": "Resurse adăugate recent",
            "icon": "fal fa-hourglass-end",
            "access": "/10/41/6/",
            "path": "ng/admin/admin/ResurseAdaugateRecent"
          },
          {
            "title": "Actualizare rute,metadate,etc...",
            "icon": "fad fa-sync-alt",
            "access": "/10/41/7/",
            "path": "ng/admin/admin/dezvoltare/actualizare"
          },
          {
            "title": "Administrare branch",
            "icon": "fal fa-code-branch",
            "access": "/10/41/8/",
            "path": "ng/admin/admin/dezvoltare/branch"
          }
        ]
      },
      {
        "title": "Utilizatori",
        "icon": "fal fa-users-cog",
        "access": "/10/46/",
        "child": [
          {
            "title": "Administrare utilizatori",
            "icon": "fal fa-users-cog",
            "access": "/10/46/1/",
            "path": "ng/admin/admin/User"
          },
          {
            "title": "Administrare utilizatori externi",
            "icon": "fal fa-clipboard-user",
            "access": "/10/46/2/",
            "path": "ng/admin/admin/UserExtern"
          }
        ]
      },
      {
        "title": "Backup",
        "icon": "fal fa-database",
        "access": "/10/48/",
        "child": [
          {
            "title": "Backup folder",
            "icon": "fal fa-folders",
            "access": "/10/48/17/",
            "path": "ng/admin/admin/backup/backupFolder"
          },
          {
            "title": "Backup server",
            "icon": "fal fa-coins",
            "access": "/10/48/19/",
            "path": "ng/admin/admin/backup/servere"
          },
          {
            "title": "Setări backup",
            "icon": "fal fa-cogs",
            "access": "/10/48/20/",
            "path": "ng/admin/admin/backup/backupPlan"
          },
          {
            "title": "Statistici backup",
            "icon": "fal fa-chart-bar",
            "access": "/10/48/21/",
            "path": "ng/admin/admin/backup/backupFile"
          }
        ]
      },
      {
        "title": "Rapoarte",
        "icon": "fal fa-archive",
        "access": "/10/74/",
        "child": [
          {
            "title": "Rapoarte",
            "icon": "fal fa-file-alt",
            "access": "/10/74/1/",
            "path": "ng/admin/admin/Rapoarte",
            "child": [
              {
                "title": "Situație rapoarte",
                "icon": "fal fa-file-chart-line",
                "access": "/10/74/1/5/",
                "path": "ng/admin/admin/SituatieRapoarte"
              }
            ]
          },
          {
            "title": "Semnături pe rapoarte",
            "icon": "fal fa-money-check-edit",
            "access": "/10/74/3/",
            "path": "ng/admin/admin/semnaturirapoarte"
          },
          {
            "title": "Definire Semnături",
            "icon": "fal fa-file-edit",
            "access": "/10/74/4/",
            "path": "ng/admin/admin/antet"
          }
        ]
      },
      {
        "title": "Sistem",
        "icon": "fal fa-cogs",
        "access": "/10/75/",
        "child": [
          {
            "title": "Setări",
            "icon": "fal fa-cog",
            "access": "/10/75/1/",
            "path": "ng/admin/admin/Setare"
          },
          {
            "title": "Test Typeahead",
            "icon": "fal fa-file",
            "access": "/10/75/4/",
            "path": "ng/admin/admin/testtypeahead/testtypeahead/"
          },
          {
            "title": "Relații tabele",
            "icon": "fal fa-code-branch",
            "access": "/10/75/6/",
            "path": "ng/admin/RelatiiTabele"
          },
          {
            "title": "Import adrese",
            "icon": "fal fa-building",
            "access": "/10/75/9/",
            "path": "ng/admin/admin/import/adrese"
          },
          {
            "title": "Întreţinere baze de date",
            "icon": "fal fa-database",
            "access": "/10/75/10/",
            "path": "ng/admin/admin/intretinere/intretineredb"
          },
          {
            "title": "Configurare Reporting Services",
            "icon": "fal fa-file-alt",
            "access": "/10/75/11/",
            "path": "ng/admin/admin/reporting/config/"
          },
          {
            "title": "Vizualizare log-uri (debug)",
            "icon": "far fa-window-alt",
            "access": "/10/75/12/",
            "path": "ng/admin/admin/Debug"
          },
          {
            "title": "Actualizare nomenclatoare",
            "icon": "far fa-arrow-alt-square-down",
            "access": "/10/75/13/",
            "path": "ng/admin/admin/testdataservice/testdataservice"
          },
          {
            "title": "Informaţii despre sistem",
            "icon": "far fa-info-square",
            "access": "/10/75/14/",
            "path": "ng/admin/admin/SystemInfo/"
          },
          {
            "title": "Actualizare nomenclatoare2",
            "icon": "far fa-arrow-alt-square-down",
            "access": "/10/75/17/",
            "path": "ng/admin/admin/nomenclatoare/actualizareNomenclatoare/"
          }
        ]
      }
    ]
  },
  {
    "title": "Administrare instituţie",
    "icon": "fal fa-building",
    "access": "/11/",
    "child": [
      {
        "title": "Unităţi",
        "icon": "fal fa-building",
        "access": "/11/1/",
        "path": "/administrare/Unitati/"
      },
      {
        "title": "Organizare instituţională",
        "icon": "fal fa-sitemap",
        "access": "/11/2/",
        "path": "/administrare/OrganizareInstitutionala"
      },
      {
        "title": "Organigramă",
        "icon": "fal fa-list",
        "access": "/11/3/",
        "path": "/administrare/Organigrama"
      },
      {
        "title": "Tipuri Venituri / Încasări Asociate cu Unitatea",
        "icon": "fal fa-list",
        "access": "/11/5/",
        "path": "/administrareinstitutie/tipvenit/tipurivenituri"
      },
      {
        "title": "Asocieri Activități contabile/economice - Bugete",
        "icon": "fal fa-file",
        "access": "/11/6/",
        "path": "/Contab/AsociereAECapitolArticol"
      },
      {
        "title": "Operațiuni, Proiecte clasificație multiplă",
        "icon": "fas fa-compress-alt",
        "access": "/11/7/",
        "path": "/administrare/asociereClasificatieMultipla"
      }
    ]
  },
  {
    "title": "Persoane",
    "icon": "fal fa-users",
    "access": "/13/",
    "child": [
      {
        "title": "Persoane",
        "icon": "fal fa-folder",
        "access": "/13/3/",
        "child": [
          {
            "title": "Persoane",
            "icon": "fal fa-users",
            "access": "/13/3/1/",
            "path": "ng/persoana/persoane/NomenclatorPersoane"
          },
          {
            "title": "Persoane în unitate",
            "icon": "fal fa-address-card",
            "access": "/13/3/2/",
            "path": "ng/persoana/persoane/persoanaunitate"
          },
          {
            "title": "Comisii",
            "icon": "fal fa-question-circle",
            "access": "/13/3/3/",
            "path": "ng/persoana/comisii"
          },
          {
            "title": "Controlori Viza CFP",
            "icon": "fal fa-check-square",
            "access": "/13/3/4/",
            "path": "ng/persoana/persoane/controloriVizaCFP"
          }
        ]
      },
      {
        "title": "Bănci",
        "icon": "fal fa-folder",
        "access": "/13/7/",
        "child": [
          {
            "title": "Bănci",
            "icon": "fal fa-building",
            "access": "/13/7/1/",
            "path": "ng/persoana/persoane/banca/NomenclatorBanci/"
          },
          {
            "title": "IBAN",
            "icon": "fal fa-book",
            "access": "/13/7/3/",
            "path": "ng/persoana/iban"
          }
        ]
      },
      {
        "title": "Configurări",
        "icon": "fal fa-folder",
        "access": "/13/18/",
        "child": [
          {
            "title": "Setări aplicație",
            "icon": "fal fa-file",
            "access": "/13/18/1/",
            "path": "ng/persoana/persoane/config/config/"
          },
          {
            "title": "Asociere furnizori și tipuri de utilități",
            "icon": "fal fa-tty",
            "access": "/13/18/2/",
            "path": "ng/persoana/persoane/utilitati/asociereutilitati"
          }
        ]
      }
    ]
  },
  {
    "title": "Adrese",
    "icon": "fal fa-globe",
    "access": "/14/",
    "child": [
      {
        "title": "Adrese intravilan",
        "icon": "fal fa-folder",
        "access": "/14/20/",
        "child": [
          {
            "title": "Adrese",
            "icon": "fal fa-file",
            "access": "/14/20/1/",
            "path": "ng/adresa/adrese/strada/adrese"
          },
          {
            "title": "Zonare fiscală",
            "icon": "fal fa-file",
            "access": "/14/20/2/",
            "path": "ng/adresa/adrese/zonarefiscala"
          }
        ]
      },
      {
        "title": "Adrese extravilan",
        "icon": "fal fa-folder",
        "access": "/14/21/",
        "child": [
          {
            "title": "Tarla, parcelă",
            "icon": "fal fa-file",
            "access": "/14/21/1/",
            "path": "ng/adresa/adrese/tarlaparcela"
          },
          {
            "title": "Zonare fiscală parcelă",
            "icon": "fal fa-file",
            "access": "/14/21/2/",
            "path": "ng/adresa/adrese/zonarefiscalaparcele"
          }
        ]
      },
      {
        "title": "Unificări",
        "icon": "fal fa-folder",
        "access": "/14/22/",
        "child": [
          {
            "title": "Unificare străzi",
            "icon": "fal fa-file",
            "access": "/14/22/1/",
            "path": "ng/adresa/adrese/unificarestrazi"
          },
          {
            "title": "Unificare numere poștale",
            "icon": "fal fa-file",
            "access": "/14/22/2/",
            "path": "ng/adresa/adrese/unificarenumere"
          },
          {
            "title": "Unificare blocuri",
            "icon": "fal fa-file",
            "access": "/14/22/3/",
            "path": "ng/adresa/adrese/unificareblocuri"
          },
          {
            "title": "Unificare scări",
            "icon": "fal fa-file",
            "access": "/14/22/4/",
            "path": "ng/adresa/adrese/unificarescari"
          },
          {
            "title": "Unificare apartamente",
            "icon": "fal fa-file",
            "access": "/14/22/5/",
            "path": "ng/adresa/adrese/unificareapartamente"
          }
        ]
      },
      {
        "title": "Zonare administrativă",
        "icon": "fal fa-folder",
        "access": "/14/23/",
        "child": [
          {
            "title": "Tip zone",
            "icon": "fal fa-file",
            "access": "/14/23/1/",
            "path": "ng/adresa/adrese/regiunetip"
          },
          {
            "title": "Zone",
            "icon": "fal fa-file",
            "access": "/14/23/2/",
            "path": "ng/adresa/adrese/regiune"
          },
          {
            "title": "Zonare administrativă",
            "icon": "fal fa-file",
            "access": "/14/23/3/",
            "path": "ng/adresa/adrese/zonareadministrativa"
          }
        ]
      },
      {
        "title": "Configurări",
        "icon": "fal fa-folder",
        "access": "/14/24/",
        "child": [
          {
            "title": "Setări aplicație",
            "icon": "fal fa-file",
            "access": "/14/24/1/",
            "path": "ng/adresa/adrese/adreseconfig"
          }
        ]
      }
    ]
  },
  {
    "title": "Documente",
    "icon": "fal fa-file-alt",
    "access": "/15/",
    "child": [
      {
        "title": "Furnizori",
        "icon": "fal fa-folder",
        "access": "/15/3/",
        "child": [
          {
            "title": "Documente furnizori",
            "icon": "fal fa-file-invoice",
            "access": "/15/3/2/",
            "path": "ng/document/document/furnizor"
          },
          {
            "title": "Documente furnizori cu sold inițial",
            "icon": "fal fa-file-invoice",
            "access": "/15/3/6/",
            "path": "ng/document/document/furnizorCuSoldInitial"
          },
          {
            "title": "Contracte",
            "icon": "fal fa-file-contract",
            "access": "/15/3/8/",
            "path": "ng/document/documente/contracte"
          },
          {
            "title": "Comenzi",
            "icon": "fal fa-file-invoice-dollar",
            "access": "/15/3/9/",
            "path": "ng/document/comenzi/"
          }
        ]
      },
      {
        "title": "Clienți",
        "icon": "fal fa-folder",
        "access": "/15/7/",
        "child": [
          {
            "title": "Documente clienți / debitori / creanțe",
            "icon": "fal fa-file-alt",
            "access": "/15/7/1/",
            "path": "ng/document/documente/clienti/"
          },
          {
            "title": "Documente clienți cu sold inițial",
            "icon": "fal fa-file-alt",
            "access": "/15/7/2/",
            "path": "ng/document/document/clientiCuSoldInitial"
          }
        ]
      },
      {
        "title": "Alte operațiuni",
        "icon": "fal fa-folder",
        "access": "/15/13/",
        "child": [
          {
            "title": "Operațiuni proprii",
            "icon": "fal fa-file-spreadsheet",
            "access": "/15/13/1/",
            "path": "ng/document/documente/alteoperatiuni/"
          },
          {
            "title": "eFactura",
            "icon": "fal fa-file-alt",
            "access": "/15/13/2/",
            "path": "ng/document/documente/eFactura/"
          }
        ]
      },
      {
        "title": "Lichidare state de plată",
        "icon": "fal fa-folder",
        "access": "/15/15/",
        "child": [
          {
            "title": "Documente state de plată",
            "icon": "fal fa-sack-dollar",
            "access": "/15/15/3/",
            "path": "ng/document/listaExecutieSalarii"
          }
        ]
      },
      {
        "title": "Control financiar preventiv",
        "icon": "fal fa-folder",
        "access": "/15/16/",
        "child": [
          {
            "title": "Operațiuni CFP asociate tipurilor de documente",
            "icon": "fal fa-ballot-check",
            "access": "/15/16/2/",
            "path": "ng/document/vizacfp/tipDocOpCFP/"
          },
          {
            "title": "Registru viză CFP",
            "icon": "fal fa-file-alt",
            "access": "/15/16/3/",
            "path": "ng/document/vizacfp/registruVizaCFP/"
          }
        ]
      },
      {
        "title": "Garanții furnizor",
        "icon": "fal fa-folder",
        "access": "/15/18/",
        "child": [
          {
            "title": "Garanţii de bună execuţie",
            "icon": "fal fa-money-bill",
            "access": "/15/18/1/",
            "path": "ng/document/garantiiDeBunaExecutie"
          },
          {
            "title": "Garanţii de participare la licitaţie",
            "icon": "fal fa-file-invoice-dollar",
            "access": "/15/18/2/",
            "path": "ng/document/garantiiParticipareLicitatie"
          }
        ]
      },
      {
        "title": "Rapoarte",
        "icon": "fal fa-folder",
        "access": "/15/19/",
        "child": [
          {
            "title": "Rapoarte",
            "icon": "fas fa-print",
            "access": "/15/19/1/",
            "path": "ng/document/rapoarte"
          }
        ]
      },
      {
        "title": "Configurări",
        "icon": "fal fa-folder",
        "access": "/15/20/",
        "child": [
          {
            "title": "Setări aplicație",
            "icon": "fal fa-cogs",
            "access": "/15/20/2/",
            "path": "ng/document/documente/configurari/config/"
          },
          {
            "title": "Alocare număr",
            "icon": "fal fa-file",
            "access": "/15/20/3/",
            "path": "ng/document/documente/configurari/alocarenumarchitanta"
          },
          {
            "title": "Setări viză CFP",
            "icon": "fal fa-file",
            "access": "/15/20/4/",
            "path": "ng/document/documente/configurari/setarivizacfp"
          }
        ]
      }
    ]
  },
  {
    "title": "Buget",
    "icon": "fal fa-chart-bar",
    "access": "/16/",
    "child": [
      {
        "title": "Prevederi",
        "icon": "fal fa-folder",
        "access": "/16/33/",
        "child": [
          {
            "title": "Prevederi detalieri",
            "icon": "fal fa-file",
            "access": "/16/33/1/",
            "path": "ng/buget/buget/prevederi/Prevederi/"
          },
          {
            "title": "Prevederi buget",
            "icon": "fal fa-table",
            "access": "/16/33/2/",
            "path": "ng/buget/buget/PrevederiBuget/"
          }
        ]
      },
      {
        "title": "Execuție bugetară",
        "icon": "fal fa-folder",
        "access": "/16/35/",
        "child": [
          {
            "title": "Executie bugetară",
            "icon": "fal fa-chart-bar",
            "access": "/16/35/5/",
            "path": "ng/buget/buget/executie/executie/executiebugetara/"
          },
          {
            "title": "Cereri deschideri și retrageri de credite",
            "icon": "fal fa-file",
            "access": "/16/35/8/",
            "path": "ng/buget/executie/cererideschidericredite"
          },
          {
            "title": "Deschideri și retrageri de credite",
            "icon": "fal fa-file",
            "access": "/16/35/9/",
            "path": "ng/buget/buget/executie/executie/deschideridecredite"
          },
          {
            "title": "Transfer solduri ani precedenți",
            "icon": "fal fa-arrow-to-right",
            "access": "/16/35/10/",
            "path": "ng/buget/buget/executie/executie/transferSolduri/"
          }
        ]
      },
      {
        "title": "Bugete/rectificări",
        "icon": "fal fa-folder",
        "access": "/16/36/",
        "child": [
          {
            "title": "Bugete/Rectificări",
            "icon": "fal fa-calculator",
            "access": "/16/36/1/",
            "path": "ng/buget/buget/Rectificari"
          },
          {
            "title": "Restaurare buget provizoriu/inițial/rectificări",
            "icon": "fal fa-recycle",
            "access": "/16/36/2/",
            "path": "ng/buget/buget/restaurareRectificari"
          }
        ]
      },
      {
        "title": "Rapoarte",
        "icon": "fal fa-folder",
        "access": "/16/38/",
        "child": [
          {
            "title": "Plăți/Cheltuieli",
            "icon": "fal fa-file",
            "access": "/16/38/1/",
            "path": "ng/buget/buget/PlatiCheltuieli"
          },
          {
            "title": "Listare Rapoarte",
            "icon": "fal fa-file",
            "access": "/16/38/2/",
            "path": "ng/buget/buget/rapoarte/listarerapoarte"
          }
        ]
      },
      {
        "title": "Nomenclatoare",
        "icon": "fal fa-folder",
        "access": "/16/40/",
        "child": [
          {
            "title": "Nomenclator detalieri",
            "icon": "fal fa-list",
            "access": "/16/40/7/",
            "path": "ng/buget/buget/Nomenclator/detalieri"
          },
          {
            "title": "Nomenclator Cod - Indicator angajament",
            "icon": "fal fa-list",
            "access": "/16/40/8/",
            "path": "ng/buget/buget/Nomenclator/codAngajament/"
          }
        ]
      },
      {
        "title": "Formulare",
        "icon": "fal fa-folder",
        "access": "/16/41/",
        "child": [
          {
            "title": "Formular detalieri",
            "icon": "fal fa-list-ul",
            "access": "/16/41/1/",
            "path": "ng/buget/buget/formular/formular/formularDetalieri_v2/"
          },
          {
            "title": "Formule detalieri",
            "icon": "fal fa-superscript",
            "access": "/16/41/3/",
            "path": "ng/buget/buget/formular/formular/formuledetalieri/"
          },
          {
            "title": "Formular buget",
            "icon": "fal fa-list-alt",
            "access": "/16/41/4/",
            "path": "ng/buget/buget/formularBuget/formularBuget/"
          },
          {
            "title": "Formule buget",
            "icon": "fal fa-superscript",
            "access": "/16/41/6/",
            "path": "ng/buget/buget/FormularBuget/formuleBuget/"
          }
        ]
      },
      {
        "title": "Configurări",
        "icon": "fal fa-folder",
        "access": "/16/42/",
        "child": [
          {
            "title": "Setări aplicație",
            "icon": "fal fa-cog",
            "access": "/16/42/1/",
            "path": "ng/buget/buget/ConfigBuget"
          }
        ]
      }
    ]
  },
  {
    "title": "Contab",
    "icon": "fal fa-university",
    "access": "/17/",
    "child": [
      {
        "title": "Actualizări",
        "icon": "fal fa-folder",
        "access": "/17/5/",
        "child": [
          {
            "title": "Actualizare balanță",
            "icon": "fal fa-calculator",
            "access": "/17/5/1/",
            "path": "ng/conta/contab/ActualizareBalantaV2"
          },
          {
            "title": "Validare înregistrări contabile",
            "icon": "fal fa-file",
            "access": "/17/5/2/",
            "path": "ng/conta/contab/notePropuse"
          },
          {
            "title": "Înregistrări contabile",
            "icon": "fal fa-file-alt",
            "access": "/17/5/4/",
            "path": "ng/conta/contab/note"
          },
          {
            "title": "Reglări înregistrări contabile",
            "icon": "fal fa-file-signature",
            "access": "/17/5/5/",
            "path": "ng/conta/documente/altedocumente/"
          },
          {
            "title": "Sold inițial partener",
            "icon": "fal fa-industry",
            "access": "/17/5/6/",
            "path": "ng/conta/contab/soldinitialpartener"
          },
          {
            "title": "Închidere manuală conturi",
            "icon": "fal fa-calendar-times",
            "access": "/17/5/7/",
            "path": "ng/conta/contab/Inchideri/"
          }
        ]
      },
      {
        "title": "Situații",
        "icon": "fal fa-folder",
        "access": "/17/8/",
        "child": [
          {
            "title": "Jurnal general",
            "icon": "fal fa-list",
            "access": "/17/8/1/",
            "path": "ng/conta/contab/jurnalGeneral/"
          },
          {
            "title": "Fișa contului",
            "icon": "fal fa-file-alt",
            "access": "/17/8/2/",
            "path": "ng/conta/contab/centralizatoare/centralizatorFisaContului/"
          },
          {
            "title": "Vizualizare balanță",
            "icon": "fal fa-eye",
            "access": "/17/8/3/",
            "path": "ng/conta/contab/VizualizareBalanta/"
          },
          {
            "title": "Facturi restante furnizori",
            "icon": "fal fa-newspaper",
            "access": "/17/8/5/",
            "path": "ng/conta/contab/facturiRestanteFurnizori/"
          },
          {
            "title": "Registrul jurnal",
            "icon": "fal fa-list-alt",
            "access": "/17/8/6/",
            "path": "ng/conta/contab/registruJurnal/"
          },
          {
            "title": "Registru inventar",
            "icon": "fal fa-bars",
            "access": "/17/8/7/",
            "path": "ng/conta/contab/registruInventar/"
          },
          {
            "title": "Registru cartea mare",
            "icon": "fal fa-database",
            "access": "/17/8/8/",
            "path": "ng/conta/contab/registruCarteMare/"
          },
          {
            "title": "Registru cartea mare sah",
            "icon": "fal fa-address-book",
            "access": "/17/8/9/",
            "path": "ng/conta/contab/registruCarteMareSah/"
          },
          {
            "title": "Situatia pe parteneri",
            "icon": "fal fa-file",
            "access": "/17/8/11/",
            "path": "ng/conta/contab/situatieparteneriV3"
          },
          {
            "title": "Declaratia 394",
            "icon": "fal fa-file",
            "access": "/17/8/12/",
            "path": "ng/conta/Contab/D394/"
          }
        ]
      },
      {
        "title": "Rapoarte",
        "icon": "fal fa-folder",
        "access": "/17/9/",
        "child": [
          {
            "title": "Fișă înregistrări contabile",
            "icon": "fal fa-file-pdf",
            "access": "/17/9/1/",
            "path": "ng/conta/contab/fisaInregistrariContabile/"
          },
          {
            "title": "Situație Cheltuieli pe Centre de cheltuială",
            "icon": "fal fa-file",
            "access": "/17/9/2/",
            "path": "ng/conta/contab/rapoarte/SituatieCheltuieli/CentreCheltuiala/"
          },
          {
            "title": "Situații zilnice",
            "icon": "fal fa-file",
            "access": "/17/9/3/",
            "path": "ng/conta/contab/rapoarte/situatiiZilnice/"
          },
          {
            "title": "Jurnale analitice",
            "icon": "fal fa-file",
            "access": "/17/9/4/",
            "path": "ng/conta/contab/rapoarte/jurnaleAnalitice/"
          },
          {
            "title": "Situatie facturi - plati aferente",
            "icon": "fal fa-file",
            "access": "/17/9/5/",
            "path": "ng/conta/contab/rapoarte/situatieFacturiPlatiAferente/"
          },
          {
            "title": "Jurnal de cumpărări",
            "icon": "fal fa-file",
            "access": "/17/9/6/",
            "path": "ng/conta/contab/rapoarte/raportJurnalCumparari/"
          },
          {
            "title": "Jurnal de vânzări",
            "icon": "fal fa-file",
            "access": "/17/9/8/",
            "path": "ng/conta/contab/rapoarte/raportJurnalVanzari/"
          }
        ]
      },
      {
        "title": "Centralizatoare",
        "icon": "fal fa-folder",
        "access": "/17/10/",
        "child": [
          {
            "title": "Centralizator balanță",
            "icon": "fal fa-file",
            "access": "/17/10/1/",
            "path": "ng/conta/contab/centralizatoare/centralizatorBalantaSetari"
          },
          {
            "title": "Centralizator fișa contului",
            "icon": "fal fa-file",
            "access": "/17/10/2/",
            "path": "ng/conta/contab/centralizatoare/centralizatorFisacontuluiSetari/"
          },
          {
            "title": "Lista Conturi",
            "icon": "fal fa-file",
            "access": "/17/10/4/",
            "path": "ng/conta/contab/centralizatoare/centralizatorListaConturi/"
          }
        ]
      },
      {
        "title": "Generale",
        "icon": "fal fa-folder",
        "access": "/17/13/",
        "child": [
          {
            "title": "Blocare lună",
            "icon": "fal fa-calendar",
            "access": "/17/13/1/",
            "path": "ng/conta/contab/InchidereLuna/"
          },
          {
            "title": "Configurare închidere conturi",
            "icon": "fal fa-sliders-h",
            "access": "/17/13/2/",
            "path": "ng/conta/contab/ConturiInchidere"
          },
          {
            "title": "Căutare documente",
            "icon": "fal fa-search",
            "access": "/17/13/3/",
            "path": "ng/conta/contab/cautareDocumente"
          }
        ]
      },
      {
        "title": "Verificari",
        "icon": "fal fa-folder",
        "access": "/17/24/",
        "child": [
          {
            "title": "Verificare sume buget - contabilitate",
            "icon": "fal fa-file",
            "access": "/17/24/1/",
            "path": "ng/conta/contab/DiferenteSumeBugetConta"
          }
        ]
      },
      {
        "title": "Nomenclatoare",
        "icon": "fal fa-folder",
        "access": "/17/25/",
        "child": [
          {
            "title": "Conturi",
            "icon": "fal fa-university",
            "access": "/17/25/1/",
            "path": "ng/conta/contab/Conturi"
          },
          {
            "title": "Jurnale",
            "icon": "fal fa-book",
            "access": "/17/25/2/",
            "path": "ng/conta/contab/Jurnale"
          }
        ]
      },
      {
        "title": "Șabloane și algoritmi",
        "icon": "fal fa-folder",
        "access": "/17/26/",
        "child": [
          {
            "title": "Șabloane de conturi",
            "icon": "fal fa-clone",
            "access": "/17/26/1/",
            "path": "ng/conta/contab/sabloaneDeConturi"
          },
          {
            "title": "Șabloane înregistrări contabile",
            "icon": "fal fa-file",
            "access": "/17/26/2/",
            "path": "ng/conta/contab/sabloaneConturiPeOperatiuni"
          },
          {
            "title": "Algoritmi de generare înregistrări contabile",
            "icon": "fal fa-superscript",
            "access": "/17/26/3/",
            "path": "ng/conta/contab/algoritmiDeGenerareNoteContabile"
          },
          {
            "title": "Șabloane închidere conturi",
            "icon": "fal fa-file",
            "access": "/17/26/7/",
            "path": "ng/conta/contab/SabloaneConturiInchidere"
          },
          {
            "title": "Șabloane asocieri credite bugetare (clasa 8)",
            "icon": "fal fa-file",
            "access": "/17/26/10/",
            "path": "ng/conta/contab/asociereContTipCreditBugetarV3"
          }
        ]
      },
      {
        "title": "Configurări",
        "icon": "fal fa-folder",
        "access": "/17/27/",
        "child": [
          {
            "title": "Setări aplicație",
            "icon": "fal fa-cogs",
            "access": "/17/27/1/",
            "path": "ng/conta/Contab/Conta"
          },
          {
            "title": "Grupare conturi facturi restante",
            "icon": "fal fa-tasks",
            "access": "/17/27/2/",
            "path": "ng/conta/contab/grupareConturi/"
          }
        ]
      }
    ]
  },
  {
    "title": "Stocuri și imobilizări",
    "icon": "fal fa-warehouse-alt",
    "access": "/19/",
    "child": [
      {
        "title": "Documente",
        "icon": "fal fa-file-edit",
        "access": "/19/1/",
        "child": [
          {
            "title": "Procese verbale trecere obiective în MF",
            "icon": "fal fa-list-alt",
            "access": "/19/1/4/",
            "path": "ng/inventar/obiectiveInCursDeExecutie"
          },
          {
            "title": "Bonuri de consum/ieșire/casare",
            "icon": "fal fa-long-arrow-right",
            "access": "/19/1/5/",
            "path": "ng/inventar/iesiri"
          },
          {
            "title": "Bonuri de transfer",
            "icon": "fal fa-exchange",
            "access": "/19/1/6/",
            "path": "ng/inventar/transferuri",
            "child": [
              {
                "title": "Mijloc fix->Obiect Inventar (ng2)",
                "icon": "fal fa-file",
                "access": "/19/1/6/2/8/",
                "path": "ng/inventar/transferuri/operareMFtoOI_ng2"
              }
            ]
          },
          {
            "title": "Bonuri de transformare",
            "icon": "fal fa-exchange",
            "access": "/19/1/7/",
            "path": "ng/inventar/transformari"
          },
          {
            "title": "Restituiri din consumuri",
            "icon": "fal fa-long-arrow-left",
            "access": "/19/1/8/",
            "path": "ng/inventar/restituiri"
          },
          {
            "title": "Dare în consum - alimente",
            "icon": "fal fa-handshake",
            "access": "/19/1/9/",
            "path": "ng/inventar/inventare/dareconsum/consumalimente"
          },
          {
            "title": "Listă necesar aprovizionare",
            "icon": "fab fa-dropbox",
            "access": "/19/1/10/",
            "path": "ng/inventar/inventare/necesar/aprovizionare"
          },
          {
            "title": "Propuneri de casare",
            "icon": "fal fa-recycle",
            "access": "/19/1/11/",
            "path": "ng/inventar/inventare/propuneriDeCasare"
          }
        ]
      },
      {
        "title": "Mijloace fixe",
        "icon": "fal fa-car-building",
        "access": "/19/2/",
        "child": [
          {
            "title": "Catalogul mijloacelor fixe",
            "icon": "fal fa-list-alt",
            "access": "/19/2/1/",
            "path": "ng/inventar/inventare/catalogmijloacefixe"
          },
          {
            "title": "Reevaluări",
            "icon": "fal fa-repeat",
            "access": "/19/2/2/",
            "path": "ng/inventar/inventare/reevaluari"
          }
        ]
      },
      {
        "title": "Gestiuni",
        "icon": "fal fa-industry-alt",
        "access": "/19/4/",
        "child": [
          {
            "title": "Stoc Inițial",
            "icon": "fal fa-chart-area",
            "access": "/19/4/1/",
            "path": "ng/inventar/inventare/StocInitial"
          }
        ]
      },
      {
        "title": "Rapoarte",
        "icon": "fal fa-file-alt",
        "access": "/19/5/",
        "child": [
          {
            "title": "Note de recepție",
            "icon": "fal fa-long-arrow-left",
            "access": "/19/5/1/",
            "path": "ng/inventar/receptie"
          },
          {
            "title": "Inventar",
            "icon": "fal fa-ballot-check",
            "access": "/19/5/2/",
            "path": "ng/inventar/Inventare/Inventar/"
          },
          {
            "title": "Balanță",
            "icon": "fas fa-balance-scale",
            "access": "/19/5/3/",
            "path": "ng/inventar/inventare/rapoarte/balanta"
          },
          {
            "title": "Fișe produse",
            "icon": "fal fa-file-search",
            "access": "/19/5/5/",
            "path": "ng/inventar/inventare/rapoarte/fisaproduse"
          },
          {
            "title": "Rapoarte",
            "icon": "fal fa-file-check",
            "access": "/19/5/6/",
            "path": "ng/inventar/inventare/rapoarte/listareraport"
          }
        ]
      },
      {
        "title": "Nomenclatoare",
        "icon": "fal fa-list",
        "access": "/19/9/",
        "child": [
          {
            "title": "Categorii produse",
            "icon": "fal fa-filter",
            "access": "/19/9/1/",
            "path": "ng/inventar/inventare/categorieProduse"
          },
          {
            "title": "Mod de organizare Mijloace Fixe",
            "icon": "fal fa-bags-shopping",
            "access": "/19/9/3/",
            "path": "ng/inventar/inventare/modOrganizareMF"
          },
          {
            "title": "Tip de finanțare Mijloace Fixe",
            "icon": "far fa-file-invoice-dollar",
            "access": "/19/9/4/",
            "path": "ng/inventar/inventare/tipFinantare"
          },
          {
            "title": "Registru produse",
            "icon": "fal fa-database",
            "access": "/19/9/5/",
            "path": "ng/inventar/inventare/RegistruProduse/"
          },
          {
            "title": "Indici prețuri consum",
            "icon": "fal fa-analytics",
            "access": "/19/9/6/",
            "path": "ng/inventar/inventare/nomenclator/ipc"
          },
          {
            "title": "Asociere produs-aliment",
            "icon": "fal fa-arrows-h",
            "access": "/19/9/7/",
            "path": "ng/inventar/inventare/asociereProdusAliment"
          },
          {
            "title": "Unificare Produse",
            "icon": "fal fa-code-merge",
            "access": "/19/9/8/",
            "path": "ng/inventar/inventare/registruproduse/unificareproduse"
          }
        ]
      },
      {
        "title": "Configurări",
        "icon": "fal fa-cog",
        "access": "/19/10/",
        "child": [
          {
            "title": "Setări aplicație",
            "icon": "fal fa-cogs",
            "access": "/19/10/1/",
            "path": "ng/inventar/setari/inventare"
          }
        ]
      }
    ]
  },
  {
    "title": "Raportări financiare",
    "icon": "fal fa-money-check-edit-alt",
    "access": "/21/",
    "child": [
      {
        "title": "Contabilitate",
        "icon": "fal fa-folder",
        "access": "/21/1/",
        "child": [
          {
            "title": "F 1101 Balanță deschidere",
            "icon": "fal fa-file",
            "access": "/21/1/1/",
            "path": "ng/raportafi/financiare/raportarifinanciare/contab/balantadeschidere/"
          },
          {
            "title": "F 1115 Cont de Execuție non-trezor - LUNAR",
            "icon": "fal fa-file",
            "access": "/21/1/2/",
            "path": "ng/raportafi/financiare/raportarifinanciare/contab/executienontrezor"
          },
          {
            "title": "F 1102 Balanță de verificare - LUNAR",
            "icon": "fal fa-file",
            "access": "/21/1/3/",
            "path": "ng/raportafi/financiare/raportarifinanciare/contab/balantaverificare/"
          },
          {
            "title": "F 1118 Plăți restante - LUNAR",
            "icon": "fal fa-file",
            "access": "/21/1/4/",
            "path": "ng/raportafi/financiare/raportarifinanciare/contab/formular30PlatiRestante"
          },
          {
            "title": "F 1114 Formular 19 - Trimestrial",
            "icon": "fal fa-file",
            "access": "/21/1/5/",
            "path": "ng/raportafi/financiare/raportarifinanciare/contab/formular19"
          },
          {
            "title": "F 1122 Situația plăților efectuate din FEN postaderare (titlul 56 și 58) - TRIMESTRIAL (ANEXA 27)",
            "icon": "fal fa-file",
            "access": "/21/1/6/",
            "path": "ng/raportafi/financiare/raportarifinanciare/contab/formular27"
          },
          {
            "title": "F 1123 Situația plăților efectuate la titlul 61 si titlul 65 - TRIMESTRIAL (Anexa 28)",
            "icon": "fal fa-file",
            "access": "/21/1/7/",
            "path": "ng/raportafi/financiare/raportarifinanciare/contab/formular28"
          },
          {
            "title": "F 1125 Situația activelor și datoriilor financiare ale instituțiilor publice - TRIMESTRIAL (ANEXA 40)",
            "icon": "fal fa-file",
            "access": "/21/1/8/",
            "path": "ng/raportafi/financiare/raportarifinanciare/contab/formular40"
          },
          {
            "title": "F 1127 Balanță deschisă luna 12 - ANUAL",
            "icon": "fal fa-file",
            "access": "/21/1/9/",
            "path": "ng/raportafi/financiare/raportarifinanciare/contab/balantadecembrie"
          },
          {
            "title": "F 1110 Situația modificărilor în structura activelor nete/capitalurilor proprii - ANUAL (ANEXA 34)",
            "icon": "fal fa-file",
            "access": "/21/1/10/",
            "path": "ng/raportafi/financiare/raportarifinanciare/contab/formular34/"
          },
          {
            "title": "F 1105 Situația activelor fixe corporale - ANUAL (ANEXA 35A)",
            "icon": "fal fa-file",
            "access": "/21/1/11/",
            "path": "ng/raportafi/financiare/raportarifinanciare/contab/formular35a/"
          },
          {
            "title": "F 1107 Situația activelor fixe necorporale - ANUAL (ANEXA 35C)",
            "icon": "fal fa-file",
            "access": "/21/1/12/",
            "path": "ng/raportafi/financiare/raportarifinanciare/contab/formular35c/"
          },
          {
            "title": "F 1113 Situația stocurilor - ANUAL (ANEXA 37)",
            "icon": "fal fa-file",
            "access": "/21/1/13/",
            "path": "ng/raportafi/financiare/raportarifinanciare/contab/formular37/"
          },
          {
            "title": "F1133 - Alte Anexe - TRIMESTRIAL",
            "icon": "fal fa-file",
            "access": "/21/1/14/",
            "path": "ng/raportafi/financiare/raportarifinanciare/contab/alteanexe"
          },
          {
            "title": "Raportări financiare",
            "icon": "fal fa-file",
            "access": "/21/1/15/",
            "path": "ng/raportafi/financiare/raportarifinanciare/contab/raportarifinanciare"
          }
        ]
      },
      {
        "title": "Buget",
        "icon": "fal fa-folder",
        "access": "/21/2/",
        "child": [
          {
            "title": "Forexebug - Buget Individual",
            "icon": "fal fa-file",
            "access": "/21/2/1/",
            "path": "ng/raportafi/financiare/raportarifinanciare/buget/bugetindividual/"
          },
          {
            "title": "Forexebug - Buget Agregat",
            "icon": "fal fa-file",
            "access": "/21/2/2/",
            "path": "ng/raportafi/financiare/raportarifinanciare/buget/bugetagregat/"
          },
          {
            "title": "Export Loturi",
            "icon": "fal fa-download",
            "access": "/21/2/4/",
            "path": "ng/raportafi/financiare/raportarifinanciare/buget/exportLoturi/"
          }
        ]
      },
      {
        "title": "Trezorerie",
        "icon": "fal fa-folder",
        "access": "/21/3/",
        "child": [
          {
            "title": "Cont de executie",
            "icon": "fal fa-file-alt",
            "access": "/21/3/3/",
            "path": "ng/raportafi/financiare/raportarifinanciare/trezorerie/contexecutie"
          },
          {
            "title": "Actualizare suma credit",
            "icon": "fal fa-dollar-sign",
            "access": "/21/3/5/",
            "path": "ng/raportafi/financiare/raportarifinanciare/trezorerie/actualizaresumacredit"
          },
          {
            "title": "Note corecție CAB",
            "icon": "fal fa-file-alt",
            "access": "/21/3/8/",
            "path": "ng/raportafi/financiare/raportarifinanciare/trezorerie/documenteCAB"
          },
          {
            "title": "Sold inițial",
            "icon": "fal fa-dollar-sign",
            "access": "/21/3/10/",
            "path": "ng/raportafi/financiare/raportarifinanciare/trezorerie/listasoldInitial"
          }
        ]
      },
      {
        "title": "Configurări",
        "icon": "fal fa-folder",
        "access": "/21/4/",
        "child": [
          {
            "title": "Setări aplicație",
            "icon": "fal fa-file",
            "access": "/21/4/1/",
            "path": "ng/raportafi/financiare/raportarifinanciare/configurari/config/"
          },
          {
            "title": "Formulare raportări Dars|Bilanț|Situații",
            "icon": "fal fa-file",
            "access": "/21/4/2/",
            "path": "ng/raportafi/financiare/raportarifinanciare/configurari/formulare/"
          }
        ]
      },
      {
        "title": "Export",
        "icon": "fal fa-folder",
        "access": "/21/5/",
        "child": [
          {
            "title": "Export ContaAs",
            "icon": "fal fa-file",
            "access": "/21/5/1/",
            "path": "ng/raportafi/financiare/raportarifinanciare/export/exportContaAs"
          },
          {
            "title": "Export Curtea de Conturi",
            "icon": "fal fa-file",
            "access": "/21/5/2/",
            "path": "ng/raportafi/financiare/raportarifinanciare/export/exportCurteaDeConturi"
          }
        ]
      },
      {
        "title": "Dars | Bilanț | Situații",
        "icon": "fal fa-folder",
        "access": "/21/6/",
        "child": [
          {
            "title": "Formulare",
            "icon": "fal fa-file",
            "access": "/21/6/1/",
            "path": "ng/raportafi/financiare/raportarifinanciare/DarsBilantSituatii/formulare"
          },
          {
            "title": "Formulare detalii",
            "icon": "fal fa-file",
            "access": "/21/6/3/",
            "path": "ng/raportafi/financiare/raportarifinanciare/DarsBilantSituatii/formulareDetalii"
          }
        ]
      },
      {
        "title": "Alte Servicii",
        "icon": "fal fa-folder",
        "access": "/21/8/",
        "child": [
          {
            "title": "ForExeBug Documente",
            "icon": "fal fa-file",
            "access": "/21/8/1/",
            "path": "ng/raportafi/financiare/raportarifinanciare/forexebug/documente"
          }
        ]
      }
    ]
  },
  {
    "title": "Impotax",
    "icon": "fal fa-home",
    "access": "/22/",
    "child": [
      {
        "title": "Debite",
        "icon": "far fa-bars",
        "access": "/22/2/",
        "child": [
          {
            "title": "Rămășițe",
            "icon": "fal fa-link",
            "access": "/22/2/2/",
            "path": "ng/impotax/impotax/casierie/ramasita/casierie/ramasita"
          },
          {
            "title": "Compensări debite",
            "icon": "fal fa-file",
            "access": "/22/2/3/",
            "path": "ng/impotax/impotax/debite/compensari/debite/borderoucompensari"
          }
        ]
      },
      {
        "title": "Generice",
        "icon": "fal fa-asterisk",
        "access": "/22/12/",
        "child": [
          {
            "title": "Tipuri generice",
            "icon": "fal fa-asterisk",
            "access": "/22/12/49/",
            "path": "ng/impotax/impotax/MenuGenerice"
          }
        ]
      },
      {
        "title": "Configurări",
        "icon": "fal fa-folder",
        "access": "/22/16/",
        "child": [
          {
            "title": "Setări aplicație",
            "icon": "fal fa-file",
            "access": "/22/16/1/",
            "path": "ng/impotax/impotax/impotaxconfig/"
          }
        ]
      }
    ]
  },
  {
    "title": "Încasări și plăți",
    "icon": "fal fa-hand-holding-usd",
    "access": "/23/",
    "child": [
      {
        "title": "Casierie ( Încasări și plăți )",
        "icon": "fal fa-folder",
        "access": "/23/1/",
        "child": [
          {
            "title": "Încasări și plăți",
            "icon": "fal fa-file",
            "access": "/23/1/1/",
            "path": "ng/incasari/plati/impotax/casierie/casa/casierie/casa/incasare"
          },
          {
            "title": "Închiderea registrului și stabilirea soldului de casă",
            "icon": "fal fa-file",
            "access": "/23/1/5/",
            "path": "ng/incasari/plati/impotax/casierie/casa/validareincasaridepunerenumerar"
          },
          {
            "title": "Predare numerar casieriei centrale",
            "icon": "fal fa-file",
            "access": "/23/1/6/",
            "path": "ng/incasari/plati/incasariplati/documente/documente/predarebani"
          },
          {
            "title": "Operare simplificată numerar",
            "icon": "fal fa-file",
            "access": "/23/1/7/",
            "path": "ng/incasari/plati/incasariplati/registruCasaSimplificat"
          },
          {
            "title": "Situație încasări",
            "icon": "fal fa-file",
            "access": "/23/1/8/",
            "path": "ng/incasari/plati/impotax/casierie/casa/situatieincasari"
          },
          {
            "title": "Extras cont / Încasări POS",
            "icon": "fal fa-file",
            "access": "/23/1/10/",
            "path": "ng/incasari/plati/incasariplati/validareincasaripos/extrascontincasaripos"
          }
        ]
      },
      {
        "title": "Bancă / Trezorerie",
        "icon": "fal fa-folder",
        "access": "/23/2/",
        "child": [
          {
            "title": "Plăți OP",
            "icon": "fal fa-money-bill-alt",
            "access": "/23/2/1/",
            "path": "ng/incasari/plati/listaDocumentePlata"
          },
          {
            "title": "OPME",
            "icon": "fal fa-file-code",
            "access": "/23/2/2/",
            "path": "ng/incasari/plati/listaDocumentePlataOPME"
          },
          {
            "title": "Borderou plăţi OP",
            "icon": "fal fa-file",
            "access": "/23/2/3/",
            "path": "ng/incasari/plati/incasariplati/documenteplata/documenteplata/borderouop"
          },
          {
            "title": "Încasări OP şi extras",
            "icon": "fal fa-file",
            "access": "/23/2/4/",
            "path": "ng/incasari/plati/incasariplati/incasaribanca/incasaribanca/listaincasari"
          }
        ]
      },
      {
        "title": "Documente",
        "icon": "fal fa-folder",
        "access": "/23/3/",
        "child": [
          {
            "title": "Cec ridicare numerar multiplu",
            "icon": "fal fa-file",
            "access": "/23/3/1/",
            "path": "ng/incasari/plati/incasariplati/documente/listacec"
          },
          {
            "title": "Avans spre decontare / Decont direct",
            "icon": "fal fa-file",
            "access": "/23/3/2/",
            "path": "ng/incasari/plati/incasariplati/documente/avansuri"
          },
          {
            "title": "Deplasări",
            "icon": "fal fa-file",
            "access": "/23/3/3/",
            "path": "ng/incasari/plati/incasariplati/documente/deplasari"
          },
          {
            "title": "Dispoziții de plată",
            "icon": "fal fa-file",
            "access": "/23/3/4/",
            "path": "ng/incasari/plati/incasariplati/documente/dispozitiideplata"
          },
          {
            "title": "Dispoziții de încasare",
            "icon": "fal fa-file",
            "access": "/23/3/5/",
            "path": "ng/incasari/plati/incasariplati/documente/dispozitiideincasare"
          },
          {
            "title": "Foi de vărsământ",
            "icon": "fal fa-file",
            "access": "/23/3/6/",
            "path": "ng/incasari/plati/incasariplati/documente/FoiDeVarsamant/"
          }
        ]
      },
      {
        "title": "Situații",
        "icon": "fal fa-folder",
        "access": "/23/4/",
        "child": [
          {
            "title": "Registru de casă",
            "icon": "fal fa-file",
            "access": "/23/4/1/",
            "path": "ng/incasari/plati/incasariplati/situatii/registruCasa"
          },
          {
            "title": "Registru de casă pe punct de încasare",
            "icon": "fal fa-file",
            "access": "/23/4/2/",
            "path": "ng/incasari/plati/incasariplati/situatii/situatii/borderoupunctdelucru"
          },
          {
            "title": "Fișa persoanei",
            "icon": "fal fa-file",
            "access": "/23/4/3/",
            "path": "ng/incasari/plati/incasariplati/situatii/situatii/fisapersoana"
          },
          {
            "title": "Stat de plată numerar",
            "icon": "fal fa-file",
            "access": "/23/4/4/",
            "path": "ng/incasari/plati/incasariplati/documente/statdeplatanumerar"
          },
          {
            "title": "Listă borderouri încasări/restituiri",
            "icon": "fal fa-file",
            "access": "/23/4/5/",
            "path": "ng/incasari/plati/incasariplati/situatii/situatii/borderou"
          },
          {
            "title": "Listă încasări prin bancă",
            "icon": "fal fa-file",
            "access": "/23/4/6/",
            "path": "ng/incasari/plati/incasariplati/situatii/situatii/listaordinplata"
          },
          {
            "title": "Listare rapoarte",
            "icon": "fal fa-file",
            "access": "/23/4/7/",
            "path": "ng/incasari/plati/incasariplati/situatii/situatii/listarerapoarte"
          }
        ]
      },
      {
        "title": "Alte încasări",
        "icon": "fal fa-folder",
        "access": "/23/5/",
        "child": [
          {
            "title": "Import GlobalPay.ro",
            "icon": "fal fa-file",
            "access": "/23/5/1/",
            "path": "ng/incasari/plati/alteincasari/incasare/epay"
          },
          {
            "title": "Import Ghișeul.ro",
            "icon": "fal fa-file",
            "access": "/23/5/4/",
            "path": "ng/incasari/plati/incasariplati/alteincasari/incasareghiseul"
          }
        ]
      },
      {
        "title": "Configurări",
        "icon": "fal fa-folder",
        "access": "/23/6/",
        "child": [
          {
            "title": "Setări aplicaţie",
            "icon": "fal fa-file",
            "access": "/23/6/1/",
            "path": "ng/incasari/plati/incasariplati/incasare/configincasare"
          },
          {
            "title": "Setări GlobalPay.ro",
            "icon": "fal fa-file",
            "access": "/23/6/2/",
            "path": "ng/incasari/plati/alteincasari/config/gpay"
          },
          {
            "title": "Setări Ghișeul.ro",
            "icon": "fal fa-file",
            "access": "/23/6/3/",
            "path": "ng/incasari/plati/incasariplati/alteincasari/configghiseul/"
          }
        ]
      },
      {
        "title": "Extras de cont",
        "icon": "fal fa-folder",
        "access": "/23/7/",
        "child": [
          {
            "title": "Extras cont",
            "icon": "fal fa-file-alt",
            "access": "/23/7/1/",
            "path": "ng/incasari/plati/raportarifinanciare/trezorerie/extrascont",
            "child": [
              {
                "title": "Import extras cont",
                "icon": "fal fa-file-alt",
                "access": "/23/7/1/1/",
                "path": "ng/incasari/plati/raportarifinanciare/trezorerie/importextrascont"
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "title": "Achiziţii",
    "icon": "fal fa-briefcase",
    "access": "/24/",
    "child": [
      {
        "title": "Program anual",
        "icon": "fal fa-folder",
        "access": "/24/9/",
        "child": [
          {
            "title": "Referate de necesitate",
            "icon": "fal fa-newspaper",
            "access": "/24/9/1/",
            "path": "/achizitii/Propuneri"
          },
          {
            "title": "Program anual",
            "icon": "fal fa-file-alt",
            "access": "/24/9/2/",
            "path": "/achizitii/Programanual"
          },
          {
            "title": "Centralizator referate de necesitate",
            "icon": "fal fa-file",
            "access": "/24/9/3/",
            "path": "/achizitii/propuneri/propunericentralizator"
          },
          {
            "title": "Centralizator CPV-uri",
            "icon": "fal fa-file",
            "access": "/24/9/4/",
            "path": "/achizitii/propuneri/centralizatorcpv"
          }
        ]
      },
      {
        "title": "Achiziții",
        "icon": "fal fa-folder",
        "access": "/24/10/",
        "child": [
          {
            "title": "Referate de necesitate (în vederea achiziției)",
            "icon": "fal fa-hospital",
            "access": "/24/10/1/",
            "path": "/achizitii/ReferateNecesitate"
          },
          {
            "title": "Achiziţii",
            "icon": "fal fa-credit-card",
            "access": "/24/10/2/",
            "path": "/achizitii/formularAchizitii/"
          }
        ]
      },
      {
        "title": "Nomenclatoare",
        "icon": "fal fa-folder",
        "access": "/24/13/",
        "child": [
          {
            "title": "Etape",
            "icon": "fal fa-calendar",
            "access": "/24/13/2/",
            "path": "/achizitii/Etape"
          },
          {
            "title": "Criterii de atribuire",
            "icon": "fal fa-ticket",
            "access": "/24/13/3/",
            "path": "/achizitii/Criterii"
          }
        ]
      },
      {
        "title": "Configurări",
        "icon": "fal fa-folder",
        "access": "/24/15/",
        "child": [
          {
            "title": "Setări aplicație",
            "icon": "fal fa-file",
            "access": "/24/15/1/",
            "path": "/Achizitii/Achizitii"
          }
        ]
      }
    ]
  },
  {
    "title": "Resurse umane",
    "icon": "fal fa-address-card",
    "access": "/51/",
    "child": [
      {
        "title": "Pontaj",
        "icon": "fal fa-file-edit",
        "access": "/51/1/",
        "path": "resum/pontaj"
      }
    ]
  },
  {
    "title": "Asistență socială",
    "icon": "fal fa-wheelchair",
    "access": "/53/",
    "child": [
      {
        "title": "DGASPC",
        "icon": "fal fa-folder",
        "access": "/53/11/",
        "child": [
          {
            "title": "Lista de solicitări",
            "icon": "fal fa-list",
            "access": "/53/11/1/",
            "path": "/asisoc/dgaspc/listacerere"
          },
          {
            "title": "Card de parcare",
            "icon": "fal fa-car",
            "access": "/53/11/3/",
            "path": "/asisoc/dgaspc/tichetparcare/ticheteparcare"
          },
          {
            "title": "Vignete",
            "icon": "fal fa-road",
            "access": "/53/11/4/",
            "path": "/asisoc/dgaspc/vigneta/vigneta"
          },
          {
            "title": "Extrase cf",
            "icon": "fal fa-folder-open",
            "access": "/53/11/5/",
            "path": "/asisoc/dgaspc/extrasCf/extras"
          },
          {
            "title": "Scutire impozit",
            "icon": "fal fa-money-bill-alt",
            "access": "/53/11/6/",
            "path": "/asisoc/dgaspc/scutireimpozit/scutire"
          }
        ]
      },
      {
        "title": "Configurări",
        "icon": "fal fa-folder",
        "access": "/53/12/",
        "child": [
          {
            "title": "Setări aplicație",
            "icon": "fal fa-file",
            "access": "/53/12/1/",
            "path": "/Asisoc/asisoc/configAsisoc"
          }
        ]
      }
    ]
  },
  {
    "title": "Nomenclatoare generale",
    "icon": "fal fa-list",
    "access": "/57/",
    "child": [
      {
        "title": "Buget",
        "icon": "fal fa-folder",
        "access": "/57/1/",
        "child": [
          {
            "title": "Nomenclator Sector bugetar",
            "icon": "fal fa-list",
            "access": "/57/1/1/",
            "path": "ng/nomenclator/nomgen/sectorbugetar/nomenclator/sectorbugetar"
          },
          {
            "title": "Nomenclator Sursă finanțare",
            "icon": "fal fa-list",
            "access": "/57/1/2/",
            "path": "ng/nomenclator/nomgen/sursafinantare/nomenclator/sursafinantare"
          },
          {
            "title": "Clasificație Funcțională",
            "icon": "fal fa-list",
            "access": "/57/1/3/",
            "path": "ng/nomenclator/nomgen/nomenclator/clasificatiefunctionala"
          },
          {
            "title": "Clasificație Economică",
            "icon": "fal fa-list",
            "access": "/57/1/4/",
            "path": "ng/nomenclator/nomgen/clasificatieeconomica/nomenclator/clasificatieeconomica"
          },
          {
            "title": "Cod program bugetar",
            "icon": "far fa-list",
            "access": "/57/1/5/",
            "path": "ng/nomenclator/nomgen/codprogrambugetar/nomenclator/codprogrambugetar"
          }
        ]
      },
      {
        "title": "Valută",
        "icon": "fal fa-folder",
        "access": "/57/2/",
        "child": [
          {
            "title": "Curs valutar",
            "icon": "fal fa-calculator",
            "access": "/57/2/1/",
            "path": "ng/nomenclator/nomgen/cursvalutar/cursvalutar"
          }
        ]
      },
      {
        "title": "Stocuri și imobilizări",
        "icon": "fal fa-folder",
        "access": "/57/3/",
        "child": [
          {
            "title": "Clasificare produse",
            "icon": "fal fa-sitemap",
            "access": "/57/3/1/",
            "path": "ng/nomenclator/nomgen/clasificareproduse"
          }
        ]
      },
      {
        "title": "Resurse umane și salarii",
        "icon": "fal fa-folder",
        "access": "/57/4/",
        "child": [
          {
            "title": "Funcții",
            "icon": "fal fa-file",
            "access": "/57/4/1/",
            "path": "ng/nomenclator/nomgen/functii/functii"
          }
        ]
      },
      {
        "title": "Utilități",
        "icon": "fal fa-folder",
        "access": "/57/5/",
        "child": [
          {
            "title": "Tipuri de utilități",
            "icon": "fal fa-list",
            "access": "/57/5/1/",
            "path": "ng/nomenclator/nomgen/utilitati/nomenclator/tipuriutilitati"
          },
          {
            "title": "Tipuri de servicii",
            "icon": "fal fa-handshake",
            "access": "/57/5/2/",
            "path": "ng/nomenclator/nomgen/utilitati/nomenclator/tipuriservicii"
          },
          {
            "title": "Tipuri de plăți",
            "icon": "fal fa-money-bill-alt",
            "access": "/57/5/3/",
            "path": "ng/nomenclator/nomgen/utilitati/nomenclator/tipuriplatinumerar"
          }
        ]
      },
      {
        "title": "Achiziții",
        "icon": "fal fa-folder",
        "access": "/57/6/",
        "child": [
          {
            "title": "Nomenclator CPV",
            "icon": "fal fa-bars",
            "access": "/57/6/1/",
            "path": "ng/nomenclator/nomgen/cpv/"
          }
        ]
      },
      {
        "title": "Tip Venit",
        "icon": "fal fa-folder",
        "access": "/57/8/",
        "child": [
          {
            "title": "Tipuri de venituri - Lista generala",
            "icon": "fal fa-file",
            "access": "/57/8/1/",
            "path": "ng/nomenclator/nomgen/TipuriVenituri"
          }
        ]
      },
      {
        "title": "Documente",
        "icon": "fal fa-folder",
        "access": "/57/9/",
        "child": [
          {
            "title": "Operațiuni CFP",
            "icon": "fal fa-list-alt",
            "access": "/57/9/1/",
            "path": "ng/nomenclator/nomgen/documente/operatiuniCFP"
          },
          {
            "title": "Definire operațiuni proprii",
            "icon": "fal fa-file-spreadsheet",
            "access": "/57/9/2/",
            "path": "ng/nomenclator/nomgen/documente/nomenclator/operatiuniproprii/"
          }
        ]
      },
      {
        "title": "Șabloane și algoritmi",
        "icon": "fal fa-folder",
        "access": "/57/10/",
        "child": [
          {
            "title": "Șabloane de conturi",
            "icon": "fal fa-file",
            "access": "/57/10/1/",
            "path": "ng/nomenclator/nomgen/sabloaneDeConturi"
          },
          {
            "title": "Șabloane înregistrări contabile",
            "icon": "fal fa-file",
            "access": "/57/10/2/",
            "path": "ng/nomenclator/nomgen/sabloaneConturiPeOperatiuni"
          },
          {
            "title": "Asociere cont banca cu tip buget",
            "icon": "fal fa-file",
            "access": "/57/10/3/",
            "path": "ng/nomenclator/nomgen/asociereContBancaCuTipBuget/"
          }
        ]
      },
      {
        "title": "Formular DARS|BILANT|SITUATII",
        "icon": "fal fa-folder",
        "access": "/57/11/",
        "child": [
          {
            "title": "Formular DARS",
            "icon": "fal fa-file",
            "access": "/57/11/1/",
            "path": "ng/nomenclator/nomgen/darsbilantsituatii/darsbilantsituatii/formulardars"
          }
        ]
      }
    ]
  },
  {
    "title": "Deploy",
    "icon": "fal fa-inbox-out",
    "access": "/58/",
    "child": [
      {
        "title": "Baze de date",
        "icon": "fal fa-coins",
        "access": "/58/6/",
        "child": [
          {
            "title": "Deploy scripturi",
            "icon": "fal fa-database",
            "access": "/58/6/1/",
            "path": "/deploy/database/Script"
          },
          {
            "title": "Validare scripturi",
            "icon": "far fa-ballot-check",
            "access": "/58/6/2/",
            "path": "/deploy/database/Validate"
          }
        ]
      },
      {
        "title": "Date",
        "icon": "fal fa-list-alt",
        "access": "/58/7/",
        "child": [
          {
            "title": "Generare pachete date",
            "icon": "fal fa-layer-plus",
            "access": "/58/7/1/",
            "path": "/deploy/date/generarePachete"
          },
          {
            "title": "Administrare nomenclatoare",
            "icon": "fal fa-list-alt",
            "access": "/58/7/3/",
            "path": "/deploy/date/registruImportDate"
          }
        ]
      },
      {
        "title": "Surse",
        "icon": "fal fa-file-code",
        "access": "/58/8/",
        "child": [
          {
            "title": "Deploy surse",
            "icon": "far fa-file-upload",
            "access": "/58/8/1/",
            "path": "/deploy/surse/deploySurse"
          },
          {
            "title": "Validare surse",
            "icon": "fal fa-file-check",
            "access": "/58/8/2/",
            "path": "/deploy/surse/validaresurse"
          }
        ]
      },
      {
        "title": "Administrare actualizări clienți",
        "icon": "fal fa-arrow-alt-square-up",
        "access": "/58/9/",
        "path": "/deploy/administrare"
      }
    ]
  },
  {
    "title": "Conversii Date",
    "icon": "fal fa-random",
    "access": "/59/",
    "child": [
      {
        "title": "Aplicații INDECO",
        "icon": "fal fa-folder",
        "access": "/59/1/",
        "child": [
          {
            "title": "Import aplicații VFP",
            "icon": "fal fa-computer-classic",
            "access": "/59/1/1/",
            "path": "ng/conversie/conversiidate/wizard/Welcome/"
          }
        ]
      }
    ]
  },
  {
    "title": "Învățământ",
    "icon": "fal fa-graduation-cap",
    "access": "/60/",
    "child": [
      {
        "title": "Grupe",
        "icon": "fal fa-file",
        "access": "/60/1/",
        "path": "ng/invatamane/invatamant/grupe"
      },
      {
        "title": "Prezența",
        "icon": "fal fa-file",
        "access": "/60/2/",
        "path": "ng/invatamane/invatamant/prezenta/"
      },
      {
        "title": "Rapoarte",
        "icon": "fal fa-folder",
        "access": "/60/3/",
        "child": [
          {
            "title": "Restanțe si plăți în avans",
            "icon": "fal fa-file",
            "access": "/60/3/1/",
            "path": "ng/invatamane/invatamant/rapoarte/restante"
          },
          {
            "title": "Situație viză flotant",
            "icon": "fal fa-file",
            "access": "/60/3/2/",
            "path": "ng/invatamane/invatamant/rapoarte/valabilitateFlotant/"
          }
        ]
      },
      {
        "title": "Configurări",
        "icon": "fal fa-folder",
        "access": "/60/4/",
        "child": [
          {
            "title": "Setări aplicație",
            "icon": "fal fa-file",
            "access": "/60/4/1/",
            "path": "ng/invatamane/invatamant/configurari/config/"
          },
          {
            "title": "Vacanțe / zile libere legale",
            "icon": "fal fa-calendar",
            "access": "/60/4/2/",
            "path": "ng/invatamane/invatamant/configurari/vacante"
          }
        ]
      }
    ]
  },
  {
    "title": "Cantina",
    "icon": "fal fa-utensils",
    "access": "/61/",
    "child": [
      {
        "title": "Cantina",
        "icon": "fal fa-utensils",
        "access": "/61/2/",
        "child": [
          {
            "title": "Preparate",
            "icon": "fal fa-hat-chef",
            "access": "/61/2/1/",
            "path": "ng/cantina/cantina/cantina/preparate"
          },
          {
            "title": "Meniu zi",
            "icon": "fal fa-calendar-day",
            "access": "/61/2/2/",
            "path": "ng/cantina/cantina/cantina/meniu"
          },
          {
            "title": "Planificare",
            "icon": "fal fa-calendar-alt",
            "access": "/61/2/3/",
            "path": "ng/cantina/cantina/cantina/planificare"
          }
        ]
      },
      {
        "title": "Rapoarte",
        "icon": "fal fa-file-alt",
        "access": "/61/3/",
        "child": [
          {
            "title": "Listă alimente necesare",
            "icon": "fal fa-file-alt",
            "access": "/61/3/1/",
            "path": "ng/cantina/cantina/cantina/listaalimenteperioada"
          },
          {
            "title": "Listă alimente necesare/ preparat",
            "icon": "fal fa-file-alt",
            "access": "/61/3/2/",
            "path": "ng/cantina/cantina/cantina/listaalimentebucatar"
          },
          {
            "title": "Listă zilnică de alimente",
            "icon": "fal fa-file-alt",
            "access": "/61/3/3/",
            "path": "ng/cantina/cantina/cantina/listazilnicaalimente"
          }
        ]
      },
      {
        "title": "Nomenclatoare",
        "icon": "fal fa-list",
        "access": "/61/6/",
        "child": [
          {
            "title": "Alergeni/Aditivi",
            "icon": "fal fa-allergies",
            "access": "/61/6/1/",
            "path": "ng/cantina/cantina/cantina/alergen"
          },
          {
            "title": "Alimente",
            "icon": "fal fa-apple-alt",
            "access": "/61/6/2/",
            "path": "ng/cantina/cantina/cantina/alimente"
          },
          {
            "title": "Categorii beneficiari",
            "icon": "fal fa-users",
            "access": "/61/6/4/",
            "path": "ng/cantina/cantina/categorii/persoane"
          },
          {
            "title": "Oră Servire",
            "icon": "fal fa-clock",
            "access": "/61/6/5/",
            "path": "ng/cantina/cantina/oraservire/"
          },
          {
            "title": "Preparat Categorie",
            "icon": "fal fa-soup",
            "access": "/61/6/6/",
            "path": "ng/cantina/cantina/preparat"
          }
        ]
      },
      {
        "title": "Configurări",
        "icon": "fal fa-cog",
        "access": "/61/7/",
        "child": [
          {
            "title": "Setări aplicație",
            "icon": "fal fa-cogs",
            "access": "/61/7/1/",
            "path": "ng/cantina/cantina/config/cantinaconfig"
          },
          {
            "title": "Asociere beneficiari alocații",
            "icon": "fal fa-users",
            "access": "/61/7/3/",
            "path": "ng/cantina/cantina/config/asocierecategorii"
          },
          {
            "title": "Listă solduri",
            "icon": "fal fa-money-bill",
            "access": "/61/7/4/",
            "path": "ng/cantina/cantina/config/listasolduri"
          }
        ]
      }
    ]
  },
  {
    "title": "Investiții",
    "icon": "fal fa-chart-line",
    "access": "/62/",
    "child": [
      {
        "title": "Obiective în curs de execuție",
        "icon": "fal fa-folder",
        "access": "/62/2/",
        "child": [
          {
            "title": "Obiective în curs de execuție",
            "icon": "fal fa-file",
            "access": "/62/2/2/",
            "path": "/investitii/obiective"
          },
          {
            "title": "Completare solduri inițiale obiective în curs de execuție",
            "icon": "fal fa-file",
            "access": "/62/2/3/",
            "path": "/investitii/obiective/completaresolduriinitiale"
          }
        ]
      },
      {
        "title": "Configurări",
        "icon": "fal fa-folder",
        "access": "/62/3/",
        "child": [
          {
            "title": "Setări aplicație",
            "icon": "fal fa-file",
            "access": "/62/3/1/",
            "path": "/investitii/configurari/config/"
          }
        ]
      }
    ]
  },
  {
    "title": "Dezvoltare",
    "icon": "fad fa-cogs",
    "access": "/63/",
    "child": [
      {
        "title": "Dezvoltare",
        "icon": "fal fa-folder",
        "access": "/63/1/",
        "child": [
          {
            "title": "Resursa",
            "icon": "fal fa-file",
            "access": "/63/1/2/",
            "path": "ng/dev/resursa"
          },
          {
            "title": "Resursa predefinite",
            "icon": "fal fa-file",
            "access": "/63/1/3/",
            "path": "ng/dev/resursa/control"
          },
          {
            "title": "Componente",
            "icon": "fal fa-file",
            "access": "/63/1/4/",
            "path": "ng/dev/component/samples"
          },
          {
            "title": "Relatii tabele",
            "icon": "fal fa-file",
            "access": "/63/1/5/",
            "path": "ng/dev/relatie/tabele"
          },
          {
            "title": "Branch",
            "icon": "fal fa-file",
            "access": "/63/1/6/",
            "path": "ng/dev/branch"
          }
        ]
      },
      {
        "title": "Deploy",
        "icon": "fal fa-folder",
        "access": "/63/2/",
        "child": [
          {
            "title": "Baza de date",
            "icon": "fal fa-file",
            "access": "/63/2/1/",
            "path": "ng/dev/deploy/database"
          },
          {
            "title": "Validare scripturi",
            "icon": "fal fa-file",
            "access": "/63/2/2/",
            "path": "ng/dev/deploy/database/validate"
          },
          {
            "title": "Deploy surse",
            "icon": "fal fa-file",
            "access": "/63/2/3/",
            "path": "ng/dev/deploy/surse"
          },
          {
            "title": "Validare surse",
            "icon": "fal fa-file",
            "access": "/63/2/4/",
            "path": "ng/dev/deploy/surse/validate"
          },
          {
            "title": "Pachete de date",
            "icon": "fal fa-file",
            "access": "/63/2/5/",
            "path": "ng/dev/deploy/pachete/date"
          },
          {
            "title": "Changelog",
            "icon": "fal fa-file",
            "access": "/63/2/6/",
            "path": "ng/dev/deploy/changelog"
          },
          {
            "title": "Deploy",
            "icon": "fal fa-file",
            "access": "/63/2/7/",
            "path": "ng/dev/deploy"
          }
        ]
      }
    ]
  }
]