<h3 class="text-center">Indeco Soft | Sisteme Informatice, Solutii Software</h3>
<div class="row">
    <div class="col-sm-7 col-md-6 offset-md-1 d-none d-sm-block">
        <!-- remove img style when updating to bootstrap 5 -->
        <img src="assets/images/sediu.webp" class="img-fluid rounded" alt="Sediu indeco soft" style="max-width:100%" loading="lazy" />
    </div>

    <div class="col-sm-5 col-md-4">
        <form name="login" (submit)="login()" *ngIf="action == 'login'">
            <fieldset>
                <legend>
                    <i class="fa fa-1x" [ngClass]="isBusy ? 'fa-spinner fa-pulse fa-spin': 'fa-key'"></i>&nbsp;Autentificare
                </legend>
                <p class="text-success" *ngIf="message.success">{{message.success}}</p>

                <div *ngIf="isDomainActive" class="mb-2">
                    <div class="form-check form-check-inline">
                        <input type="radio" class="form-check-input" id="isSief" name="isAdLogin"
                            [(ngModel)]="isAdLogin" [value]="false" (change)="setAdChoice()" />
                        <label class="form-check-label" for="isSief">Cont Sief</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" class="form-check-input" id="isAdLogin" name="isAdLogin"
                            [(ngModel)]="isAdLogin" [value]="true" (change)="setAdChoice()" />
                        <label class="form-check-label" for="isAdLogin">Domeniu: {{domainName}}</label>
                    </div>
                </div>
                <div class="mb-3 has-search">
                    <span class="fa fa-user form-control-feedback"></span>
                    <input type="text" class="form-control" placeholder="Nume de utilizator" autofocus
                        [(ngModel)]="registration.userName" name="userName" aria-label="utilizator"
                        [disabled]="isBusy" (keydown)="messageReset()" />
                </div>
                <div class="mb-3 has-search">
                    <span class="fa fa-lock form-control-feedback"></span>
                    <input type="password" class="form-control" placeholder="Parola"
                        [(ngModel)]="registration.password" name="password" aria-label="parola"
                        [disabled]="isBusy" (keydown)="messageReset()" />
                </div>
                <div class="mb-3 form-check ms-2">
                    <input type="checkbox" class="form-check-input" id="useRefreshToken"
                        [(ngModel)]="registration.useRefreshTokens" name="useRefreshToken" />
                    <label class="form-check-label" for="useRefreshToken">Reține datele de conectare</label>
                </div>
                <div class="d-flex justify-content-between">
                    <button type="submit" id="submit" class="btn btn-primary mb-2" value="Conectare">Conectare</button>
                    <div>
                        <button type="button" class="btn btn-sm btn-link text-info py-0" (click)="goto('resetPassword')">
                            <i class="fa fa-user-times"></i>&nbsp;V-ați uitat parola?</button>
                        <br />
                        <button type="button" class="btn btn-sm btn-link text-info py-0"
                            (click)="goto('register')" *ngIf="isRegisterActive">
                            <i class="fa fa-user"></i>&nbsp;&nbsp;Crează cont nou
                        </button>
                    </div>
                </div>
                <hr />
                <p class="text-danger" *ngIf="message.error" style="white-space: pre-line">{{message.error}}</p>

                <div class="mb-3" *ngIf="isSocialActive">
                    <label class="form-label">sau te poți autentifica cu contul de </label>
                    <div class="mb-3">
                        <button class="btn facebook" (click)="authenticate('facebook')"><i class="fab fa-1x fa-facebook"></i></button>
                        <button class="btn google" (click)="authenticate('google')"><i class="fab fa-1x fa-google-plus"></i></button>
                        <button class="btn windows" (click)="authenticate('live')"><i class="fab fa-1x fa-windows"></i></button>
                        <button class="btn yahoo" (click)="authenticate('yahoo')"><i class="fab fa-1x fa-yahoo"></i></button>
                        <button class="btn linkedin" (click)="authenticate('linkedin')"><i class="fab fa-1x fa-linkedin"></i></button>
                    </div>
                </div>
            </fieldset>
        </form>

        <form #registerForm="ngForm" (submit)="register()" *ngIf="action == 'register'">
            <fieldset>
                <legend><i class="fa fa-key"></i>&nbsp;Cont de utilizator nou</legend>

                <div class="mb-3 has-search">
                    <span class="fa fa-user form-control-feedback"></span>
                    <input type="text" class="form-control" placeholder="Nume de utilizator..." autofocus
                        [(ngModel)]="registration.userName" name="registerUserName" aria-label="utilizator"
                        [disabled]="isBusy" (keydown)="messageReset()" />
                </div>
                <div class="mb-3 has-search">
                    <span class="fa fa-envelope form-control-feedback"></span>
                    <input type="email" name="registerEmail" class="form-control" placeholder="Adresa de email..."
                        [(ngModel)]="registration.email" name="registerEmail" aria-label="adresa de e-mail"
                        [disabled]="isBusy" (keydown)="messageReset()" />
                </div>
                <div class="mb-3 has-search">
                    <span class="fa fa-lock form-control-feedback"></span>
                    <input type="password" class="form-control" placeholder="Parola..."
                        [(ngModel)]="registration.password" name="registerPassword" aria-label="parola"
                        [disabled]="isBusy" (keydown)="messageReset()" />
                </div>
                <div class="mb-3 has-search">
                    <span class="fa fa-lock form-control-feedback"></span>
                    <input type="password" class="form-control" placeholder="Confirmă parola ..."
                        [(ngModel)]="registration.confirmPassword" name="confirmPassword"
                        [disabled]="isBusy" (keydown)="messageReset()" aria-label="contirma parola" />
                </div>
                <div class="d-flex justify-content-between">
                    <button type="submit" id="submit" class="btn btn-primary"
                        [disabled]="registerForm.invalid" value="Crează cont">Crează cont
                    </button>
                    <button type="reset" class="btn btn-link text-info pt-2" (click)="goto('login')">
                        <i class="fa fa-reply"></i>&nbsp;Am deja cont
                    </button>
                </div>
                <hr />
                <p class="text-success" *ngIf="message.success" style="white-space: pre-wrap;">{{message.success}}</p>
                <p class="text-danger" *ngIf="message.error" style="white-space: pre-wrap;">{{message.error}}</p>
            </fieldset>
        </form>

        <form #resetForm="ngForm" (submit)="passwordReset()" *ngIf="action == 'resetPassword'">
            <fieldset>
                <legend><i class="fa fa-key"></i>&nbsp;Recuperare cont</legend>
                <div class="mb-3 has-search">
                    <label for="resetUsername">Utilizator</label>
                    <span class="fa fa-user form-control-feedback"></span>
                    <input type="text" class="form-control" id="resetUsername" placeholder="utilizator" autofocus
                        [(ngModel)]="registration.userName" name="userName" aria-label="utilizator"
                        [disabled]="isBusy" (keydown)="messageReset()" />
                </div>
                <div class="mb-3 has-search">
                    <label for="emailAddress">Adresa de e-mail</label>
                    <span class="fa fa-envelope form-control-feedback"></span>
                    <div class="mb-3">
                        <input type="email" class="form-control" id="emailAddress" placeholder="adresa de e-mail..."
                            [(ngModel)]="registration.email" name="resetEmail" aria-label="adresa de e-mail"
                            [disabled]="isBusy" (keydown)="messageReset()" />
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <button type="submit" id="submit" class="btn btn-primary"
                        value="Trimite solicitare" [disabled]="resetForm.invalid">Trimite solicitare
                    </button>
                    <button type="button" class="btn btn-link text-info pt-2" (click)="goto('login')">
                        <i class="fa fa-reply"></i>&nbsp;Înapoi la login
                    </button>
                </div>
                <hr />
                <p class="text-success" *ngIf="message.success">{{message.success}}</p>
                <p class="text-danger" *ngIf="message.error">{{message.error}}</p>
            </fieldset>
        </form>

        <form #newPwdForm="ngForm" (submit)="newPassword()" *ngIf="action == 'newPassword'">
            <fieldset>
                <legend><i class="fa fa-key"></i>&nbsp;Recuperare parola cont</legend>
                <div class="mb-3 has-search">
                    <label for="confirmPassword" class="form-label">Parola</label>
                    <span class="fa fa-lock form-control-feedback"></span>
                    <input type="password" class="form-control"
                        placeholder="confirma parola" id="confirmPassword" autofocus
                        [(ngModel)]="registration.password" name="confirmPassword"
                        [disabled]="isBusy" (keydown)="messageReset()" />
                </div>
                <div class="mb-3 has-search">
                    <label for="ccp" class="form-label">Confirma parola</label>
                    <span class="fa fa-lock form-control-feedback"></span>
                    <input type="password" class="form-control"
                        placeholder="confirma parola" name="confirmConfirmPassword"
                        [(ngModel)]="registration.confirmPassword" id="ccp"
                        [disabled]="isBusy" (keydown)="messageReset()" />
                </div>
                <div class="d-flex justify-content-between">
                    <button type="submit" class="btn btn-primary" value="Trimite solicitare"
                        [disabled]="newPwdForm.invalid">Trimite solicitare
                    </button>
                    <button type="reset" class="btn btn-link text-info pt-2" (click)="goto('login')">
                        <i class="fa fa-reply"></i>&nbsp;Înapoi la login
                    </button>
                </div>
                <hr />
                <p class="text-success" *ngIf="message.success">{{message.success}}</p>
                <p class="text-danger" *ngIf="message.error">{{message.error}}</p>
            </fieldset>
        </form>

        <p class="text-info text-center" *ngIf="capsLockWarn && !isCapsOn">Activati CAPS LOCK</p>
        <p class="text-warning text-center" *ngIf="isCapsOn">CAPS LOCK is on</p>
    </div>
</div>