import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings, RoutingService, ControlsService, RouteBtn, StyleManager } from 'core';

@Component({
    selector: 'route-controls-btn', templateUrl: './controls.btn.html',
    standalone: false
})
export class NavbarControlsBtn implements OnInit {
    constructor(private http: HttpClient,
        private settings: AppSettings, private style: StyleManager,
        private routing: RoutingService, private controls: ControlsService,
    ) { }

    protected tooltip: string;
    @Output() onClicked = new EventEmitter<RouteBtn>();

    ngOnInit() { }

    protected getButtons() { return this.controls.getRouteButtons() }
    protected controlSize(prefix: string, btn?: RouteBtn): string {
        var css = '';
        if (btn) { css += btn.class || 'btn-primary' }
        if (this.style.css.controlsSize == 'sm') { css += ` ${prefix}-sm` }
        if (this.style.css.controlsSize == 'lg') { css += ` ${prefix}-lg` }
        return css;
    }

    protected isVisible(btn: RouteBtn): boolean {
        if (this.hasFn('isCommandVisible')) {
            return this.routing.activeRoute.isCommandVisible(btn)
        } else { return true }
    }
    protected isDisabled(btn: RouteBtn): boolean {
        if (this.http['isBusy'] /* || btn.nivelAcces < 2 */) { return true }
        if (btn['isDisabled']) {
            // setTimeout(() => { btn.tooltip = ''; delete btn['isDisabled']; }, 1000);
            return true;
        }
        try {
            if (this.hasFn('isCommandEnabled')) {
                const res = this.routing.activeRoute.isCommandEnabled(btn);
                const isString = typeof res == 'string';
                if (isString) { btn.tooltip = res }
                else if (res === true) { btn.tooltip = '' }
                return isString ? Boolean(res) : !res;
            }
            return false;
        } catch (e) { return true }
    }
    protected onClick(btn: RouteBtn) {
        if (this.hasFn('onCommandClick') /* && btn.nivelAcces == 2 */) {
            btn['isDisabled'] = true;
            btn.tooltip = 'Procesare, va rugam asteptati...';
            Promise.resolve(this.routing.activeRoute.onCommandClick(btn)).then(() => {
                btn.tooltip = '';
                delete btn['isDisabled'];
            });
            // this.logBtn(btn);
            this.onClicked.emit(btn);
        }
    }

    private hasFn(key: string) {
        return this.routing && this.routing.activeRoute && this.routing.activeRoute[key]
    }
    private logBtn(btn: RouteBtn) {
        this.http.post('/log/client/command', {
            hierarchyId: btn.hierarchyId, cod: btn.cod,
        }, { responseType: 'text' }).subscribe();
    }
}