<div class="offcanvas-header py-2 border-bottom">
	<h5 class="offcanvas-title me-auto">Help center</h5>
	<button type="button" class="btn btn-sm btn-outline-info text-reset me-1" title="Editeaza help" (click)="openHelpEditor()">
		<i class="far fa-fw fa-1x fa-edit"></i>
	</button>

	<div class="btn-group btn-group-sm me-1" role="group" display="dynamic" placement="bottom-right" ngbDropdown>
		<button type="button" class="btn btn-sm btn-outline-info" title="Schimba marimea ferestrei" ngbDropdownToggle>
			<i class="far fa-1x fa-files"></i> {{width || 'auto'}}<i [hidden]="!width" class="far fa-1x fa-percent"></i>
		</button>
		<div ngbDropdownMenu>
			<button ngbDropdownItem (click)="setSize(0)">
				<i class="fa-thin fa-fw fa-1x fa-circle"></i> auto
			</button>
			<button ngbDropdownItem (click)="setSize(25)">
				<i class="fa-solid fa-fw fa-1x fa-circle-quarter-stroke"></i> 25%
			</button>
			<button ngbDropdownItem (click)="setSize(50)">
				<i class="fa-solid fa-fw fa-1x fa-circle-half-stroke"></i> 50%
			</button>
			<button ngbDropdownItem (click)="setSize(75)">
				<i class="fa-solid fa-fw fa-1x fa-circle-three-quarters-stroke"></i> 75%
			</button>
			<button ngbDropdownItem (click)="setSize(100)">
				<i class="fa-solid fa-fw fa-1x fa-circle"></i> 100%
			</button>
		</div>
	</div>

	<div class="btn-group btn-group-sm" role="group" display="dynamic" placement="bottom-right" ngbDropdown>
		<button type="button" class="btn btn-sm btn-outline-info" title="Schimba pozitia ferestrei" ngbDropdownToggle>
			<i class="far fa-1x {{getPosIcon()}}"></i>
		</button>
		<div ngbDropdownMenu>
			<button ngbDropdownItem (click)="change('start')">
				<i class="far fa-fw fa-1x fa-arrow-square-left"></i> Stanga
			</button>
			<button ngbDropdownItem (click)="change('end')">
				<i class="far fa-fw fa-1x fa-arrow-square-right"></i> Dreapta
			</button>
			<button ngbDropdownItem (click)="change('top')">
				<i class="far fa-fw fa-1x fa-arrow-square-up"></i> Sus
			</button>
			<button ngbDropdownItem (click)="change('bottom')">
				<i class="far fa-fw fa-1x fa-arrow-square-down"></i> Jos
			</button>
		</div>
	</div>

	<button type="button" class="btn btn-sm btn-outline-info text-reset ms-1" title="Detaseaza ferestra" (click)="detachWindow()">
		<i class="fa-sharp far fa-window-restore"></i>
	</button>

	<button type="button" class="btn-close text-reset ms-1" aria-label="Close"
		(click)="activeOffcanvas.dismiss()">
	</button>
</div>

<div class="offcanvas-body d-flex flex-column flex-fill py-1 ps-1" style="padding-right: 12px">
	<div class="d-flex flex-fill flex-column" style="overflow:auto">
		<help-content #help [hierarchyId]="hierarchyId" tipAfisare="page"></help-content>
	</div>
	<div class="row">
		<div class="col">
			<button type="button" class="w-100 btn btn-sm btn-outline-dark" disabled (click)="openChat()">Chat</button>
		</div>
		<div class="col">
			<button type="button" class="w-100 btn btn-sm btn-outline-dark" (click)="openSuport()">Suport</button>
		</div>
	</div>
</div>