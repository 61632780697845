import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { mergeMap } from 'rxjs/operators';
import { ControlsService, RouteBtn, StyleManager, RoutingService, } from 'core';
import { ControlsContainer } from './controls.offcanvas';

@Component({
    selector: 'route-controls', templateUrl: './navbar.controls.html', standalone: false,
    styles: ['@media only screen and (max-width: 320px) { .w-xs-80 { width: 80%; } }']
    // styles: ['$grid-breakpoints: (xs: 320px);']
})
export class NavbarControls implements OnInit {
    constructor(private offcanvasService: NgbOffcanvas, private router: Router,
        private http: HttpClient, private routing: RoutingService,
        protected style: StyleManager, protected controls: ControlsService,
    ) {
        routing.onRouteChange.pipe(
            mergeMap(route => route.data),
        ).subscribe(data => this.setSearchContext(data))
    }

    protected searchIcon: string;
    protected searchPlaceholder: string;
    protected isSearchHidden: boolean;
    private isSearchService: boolean;

    protected get isBusy(): boolean { return this.http['isBusy'] }

    ngOnInit() { }

    protected controlSize(prefix: string, btn?: RouteBtn): string {
        var css = '';
        if (btn) { css += btn.class || 'btn-primary' }
        if (this.style.css.controlsSize == 'sm') { css += ` ${prefix}-sm` }
        if (this.style.css.controlsSize == 'lg') { css += ` ${prefix}-lg` }
        return css
    }
    private setSearchContext(options) {
        this.isSearchHidden = options.isHidden || false;
        this.searchIcon = options.isSearch ? 'fa-search' : 'fa-filter';
        this.searchPlaceholder = options.placeholder || options.isSearch ? 'Cautare...' : 'Filtrare...';
        this.isSearchService = options.isAuto || false;
    }
    protected onKeyPress(event) {
        // if (this.routing.activeRoute) {
        //     this.routing.activeRoute.searchText = this.controls.searchText || '';
        // }
        if (event.which == 13) { this.onSearch(event) }
    }
    protected onSearch(event) {
        event.preventDefault();
        if (this.isSearchService) {
            this.router.navigate([`/common/search/${encodeURIComponent(this.controls.searchText)}`])
        } else if (this.routing.activeRoute && this.routing.activeRoute.onSearch) {
            this.controls.keepSearch();
            this.routing.activeRoute.searchText = this.controls.searchText || '';
            this.routing.activeRoute.onSearch(this.controls.searchText || '');
        }
        return false
    }

    protected openBtnMenu() {
        this.offcanvasService.open(ControlsContainer, { position: 'end' });
    }
}