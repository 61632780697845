import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import {
    UserService, AppSettings, ControlsService, RouteBtn, RouteCommands, ICommand, StyleManager
} from "core";
import { fadeInOutAnimation, fadeInSlideLeftAnimation } from 'src/app/animations';
import { routes } from "./routes";

var activeLink: number;

@Component({
    selector: "home", templateUrl: "./home.html",
    styleUrls: ["./home.scss"], animations: [fadeInOutAnimation]
})
export class Home implements OnInit, RouteCommands {
    constructor(private router: Router,
        private controls: ControlsService, private settings: AppSettings,
        private user: UserService, protected style: StyleManager) {
        activeLink = +localStorage.getItem('homepage.pin') || 0;
    }

    private persoane: any[] = [];
    private favorites: string[] = [];
    private isSelectFavorites: boolean;
    private menuAll: any[] = [];
    protected menu: any[] = [];

    protected mouseover: string;
    protected get activeLink(): number { return activeLink }
    protected set activeLink(value: number) { activeLink = value }
    protected get activeRoute() {
        return this.menu[this.activeLink] && this.menu[this.activeLink].child || []
    }

    ngOnInit() {
        // if (this.activeLink === null) { this.activeLink = this.settings.homePagePin }
        this.user.nivelAcces.then((permissions) => {
            return this.menu = this.menuAll = routes.filter(app => {
                if (permissions[app.access] > 0) {
                    app.child = app.child.filter(mod => {
                        mod.child = mod.child.filter(nav => permissions[nav.access] > 0);
                        return mod.child.length > 0
                    });
                    return true
                }
            })
        });
        this.controls.addButton(new RouteBtn({
            caption: 'Marcaje pagini favorite', cod: 'showFavorites', icon: 'fa-star'
        }));
    }

    protected startsWith(path: string) { return /^#/.test(path) }
    protected setColSize() {
        return this.activeRoute.length > 3 ? 'col-4 col-xl-3' : this.activeRoute.length == 1 ? 'col-auto' : 'col'
    }
    protected setActive(route, index: number) {
        this.activeLink = index;
        route.isCollapse = !route.isCollapse;
        localStorage.setItem('homepage.pin', `${index}`);
    }

    onSearch(str?: string) {
        this.menu = JSON.parse(JSON.stringify(this.menuAll));
        if (str) {
            const search = new RegExp(str, 'i');
            this.menu = this.menu.filter(app => {
                app.child = app.child.filter(modul => {
                    modul.child = modul.child && modul.child.filter(link => search.test(link.title)) || [];
                    return modul.child.length > 0
                });
                return app.child.length > 0
            });
            this.activeLink = 0;
        }
    }
    protected onSelect(nav) {
        if (this.isSelectFavorites) {
            // this.settings.favorites = nav
        } else {
            // console.log('home route: ', nav.path)
            this.router.navigate([nav.path])
        }
    }
    onCommandClick(cmd: ICommand) {
        switch (cmd.cod) {
            case 'showFavorites':
                cmd['nume'] = this.isSelectFavorites ? 'Revenire la navigare' : 'Marcare pagini favorite';
                cmd.caption = this.isSelectFavorites ? 'Revenire la navigare' : 'Marcare pagini favorite';
                cmd.icon = this.isSelectFavorites ? 'fa-star-half-stroke' : 'fa-star';
                break;
        }
    }
}