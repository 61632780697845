import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService, AppSettings, States } from 'core';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
	constructor(private router: Router, private user: UserService, private settings: AppSettings) { }

	canActivate(
		next: ActivatedRouteSnapshot, state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (!this.user.isAuthenticated) {
			console.warn(`Unauthorized, login to continue!`);
			return this.router.parseUrl('login');
		}
		return true
	}

	canActivateChild(
		next: ActivatedRouteSnapshot, state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.user.nivelAcces.then(nivel => {
			if (nivel[next.data.access] > 0) {
				// if (!this.settings.loaded) { return this.settings.getSettings().then(() => { return true }) }
				return true
			} else {
				const url = next.pathFromRoot.map(v => v.url.map(segment => segment.toString()).join('/')).join('/');
				console.warn(`Access denied to "${url}"!`);
				return this.router.parseUrl(`${States.admin.stop}/${encodeURIComponent(url)}`);
			}
		})
	}
}


@Injectable({ providedIn: 'root' })
export class HomeSettingsGuard {
	constructor(public router: Router, private settings: AppSettings) { }

	canActivate(
		next: ActivatedRouteSnapshot, state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		// if (!this.settings.loaded) { return this.settings.getSettings().then(() => { return true }) }
		return true
	}
}