<!--navbar fixed-top navbar-expand-sm flex-column align-items-stretch-->
<nav id="mainNavbar" class="navbar navbar-expand-lg pb-1 pt-0"
    [ngClass]="[style.css.navbarType, style.css.navbarBg, style.css.navbarPosition]">
    <div class="container-fluid">
        <a routerLink="home" class="navbar-brand">
            <i *ngIf="isBusy" class="fa fa-2x fa-spin fa-spinner fa-pulse text-center" style="width:65px"></i>
            <img [hidden]="isBusy" src="assets/images/sief_logo.svg" alt="Sief" height="30" width="65" loading="lazy" />
        </a>

        <button type="button" class="navbar-toggler py-2 ms-auto" (click)="openNotificare()"
            aria-controls="notify" aria-expanded="false" aria-label="Toggle notificari"
            data-target="#notify" data-toggle="collapse">
            <i class="text-info fas fa-lg fa-globe"></i>
        </button>
        <button type="button" class="navbar-toggler ms-2" (click)="collapsePrintOp = !collapsePrintOp"
            aria-controls="printOptions" aria-expanded="false" aria-label="Toggle print options"
            data-target="#printOptions" data-toggle="collapse">
            <i class="fal fa-lg py-2" [ngClass]="report.icon()"></i>
        </button>
        <button type="button" class="navbar-toggler ms-2" (click)="collapseUserNav = !collapseUserNav"
            aria-controls="userNavigation" aria-expanded="false" aria-label="Toggle navigation"
            data-target="#userNavigation" data-toggle="collapse">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="navbar-collapse collapse" *ngIf="!style.isMobile">
            <ul class="navbar-nav flex-column">
                <li class="navbar-text py-0 lead"><i class="fal" [ngClass]="titleIcon"></i>&nbsp;{{title}}</li>
                <li><navbar-unitate></navbar-unitate></li>
            </ul>
        </div>

        <div id="printOptions" class="navbar-collapse" [ngbCollapse]="collapsePrintOp">
            <div class="container d-lg-none">
                <ul class="navbar-nav list-group-horizontal justify-content-evenly d-flex">
                    <li class="nav-item">
                        <a (click)="setReportFormat('word')"><i class="fal fa-2x fa-fw" [ngClass]="reportIcon('word')"></i>&nbsp;Word</a>
                    </li>
                    <li class="nav-item">
                        <a (click)="setReportFormat('excel')"><i class="fal fa-2x fa-fw" [ngClass]="reportIcon('excel')"></i>&nbsp;Excel</a>
                    </li>
                    <li class="nav-item">
                        <a (click)="setReportFormat('pdf')"><i class="fal fa-2x fa-fw" [ngClass]="reportIcon('pdf')"></i>&nbsp;Pdf</a>
                    </li>
                    <li class="nav-item">
                        <a (click)="setReportFormat('csv')"><i class="fal fa-2x fa-fw" [ngClass]="reportIcon('csv')"></i>&nbsp;Csv</a>
                    </li>
                </ul>
            </div>
        </div>

        <div id="userNavigation" class="navbar-collapse" [ngbCollapse]="collapseUserNav">
            <div class="border-end border-start d-none me-3"></div>
            <ul class="navbar-nav flex-column d-md-block d-lg-none" *ngIf="style.isMobile">
                <li class="navbar-text py-0 lead"><i class="fal" [ngClass]="titleIcon"></i>&nbsp;{{title}}</li>
                <li><navbar-unitate></navbar-unitate></li>
            </ul>

            <ul class="navbar-nav ms-auto d-none d-lg-block">
                <li class="nav-item d-md-none d-lg-block">
                    <a class="nav-link pointer no-arrow position-relative" role="button" (click)="openNotificare()"
                        tooltip="Notificari" title="Notificari" triggers="mouseenter" placement="bottom" aria-expanded="false">
                        <span class="fa-stack">
                            <i class="fal fa-2x fa-comment-dots text-info"></i>
                            <i class="fas fa-circle text-primary fa-stack-1x" *ngIf="notify.hasNew" style="top:-9px; left:10px"></i>
                        </span>
                    </a>
                </li>
            </ul>

            <ul class="navbar-nav d-none d-lg-block">
                <li class="nav-item" display="dynamic" placement="bottom-right" ngbDropdown>
                    <a role="button" id="printOptions" class="nav-link pointer" ngbDropdownToggle>
                        <span class="fa-stack">
                            <i class="fal fa-stack-2x" [ngClass]="report.icon()"></i>
                            <i class="fal fa-print fa-stack-1x navbar-text py-0" style="top:12px; left:12px"></i>
                        </span>
                    </a>
                    <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="printOptions">
                        <a (click)="setReportFormat('word')" ngbDropdownItem>
                            <i class="fal fa-2x fa-fw" [ngClass]="reportIcon('word')"></i>&nbsp;Word
                        </a>
                        <a (click)="setReportFormat('excel')" ngbDropdownItem>
                            <i class="fal fa-2x fa-fw" [ngClass]="reportIcon('excel')"></i>&nbsp;Excel
                        </a>
                        <a (click)="setReportFormat('pdf')" ngbDropdownItem>
                            <i class="fal fa-2x fa-fw" [ngClass]="reportIcon('pdf')"></i>&nbsp;Pdf
                        </a>
                        <a (click)="setReportFormat('csv')" ngbDropdownItem>
                            <i class="fal fa-2x fa-fw" [ngClass]="reportIcon('csv')"></i>&nbsp;Csv
                        </a>
                    </div>
                </li>
            </ul>

            <ul class="navbar-nav list-group-horizontal">
                <li class="nav-item me-auto border-start border-end" ngbDropdown display="dynamic">
                    <a role="button" class="nav-link pointer" id="navUserName" ngbDropdownToggle>
                        <i *ngIf="providerClass" class="fa-1x" [ngClass]="providerClass"></i>&nbsp;{{userName}}
                    </a>
                    <div ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu">
                        <a ngbDropdownItem routerLink="user/setare" (click)="collapseUserNav = true">
                            <i class="fal fa-fw fa-cogs"></i>&nbsp;Parametrii de lucru
                        </a>
                        <a ngbDropdownItem routerLink="user/theme" (click)="collapseUserNav = true">
                            <i class="fal fa-fw fa-cog"></i>&nbsp;Tema culori
                        </a>
                        <a ngbDropdownItem routerLink="user/cont" (click)="collapseUserNav = true">
                            <i class="fal fa-fw fa-user"></i>&nbsp;Contul meu
                        </a>
                        <div class="dropdown-divider"></div>
                        <a ngbDropdownItem href="" (click)="$event.preventDefault();logOut()">
                            <i class="fal fa-1x fa-sign-out-alt"></i>&nbsp;Deconectare
                        </a>
                    </div>
                </li>
                <li class="nav-item mx-1 mx-lg-0">
                    <a href="{{suportUrl}}" class="nav-link" role="button" (click)="collapseUserNav = true"
                        target="_blank" rel="noreferrer noopener" tooltip="Suport online" title="Suport online"
                        triggers="mouseenter" placement="bottom" aria-expanded="false">
                        <i class="far fa-user-headset" [ngClass]="style.isMobile ? 'fa-1x':'fa-2x'"></i>
                    </a>
                </li>
                <li class="nav-item mx-1 mx-md-2 mx-lg-0">
                    <a href="" class="nav-link" role="button" title="Help" tooltip="Help"
                        (click)="$event.preventDefault(); openHelp($event)"
                        aria-expanded="false" triggers="mouseenter" placement="bottom">
                        <i class="far fa-question-circle" [ngClass]="style.isMobile ? 'fa-1x':'fa-2x'"></i>
                    </a>
                </li>
                <li class="vr mx-2" *ngIf="!style.isMobile"></li>
                <li class="nav-item mx-1 mx-md-2 mx-lg-0">
                    <a href="javascript: void(0)" class="nav-link" role="button"
                        title="Trimite feedback" (click)="openFeedback()">
                        <i class="far fa-comment-exclamation" [ngClass]="style.isMobile ? 'fa-1x':'fa-2x'"></i>
                    </a>
                </li>
                <li class="nav-item mx-1 mx-md-2 mx-lg-0">
                    <a href="" class="nav-link" role="button" title="Full Screen"
                        (click)="$event.preventDefault(); toggleFullScreen()" tooltip="Full Screen"
                        triggers="mouseenter" placement="bottom" aria-expanded="false">
                        <i class="far" [ngClass]="[isFullScreenActive ? 'fa-compress-arrows-alt':'fa-expand-arrows-alt', style.isMobile ? 'fa-1x':'fa-2x']"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>