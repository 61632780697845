<div class="offcanvas-header py-1">
    <h5 class="offcanvas-title text-center">Notificari</h5>
    <button type="button" class="btn-close text-reset" aria-label="Close"
        (click)="activeOffcanvas.close()">
    </button>
</div>
<hr class="my-1" />
<div class="offcanvas-body p-0">
    <div class="ms-3 d-flex">
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="tipMesaje" id="all" [(ngModel)]="tip" [value]="false" />
            <label class="form-check-label" for="all">toate</label>
        </div>
        <div class="form-check form-check-inline flex-fill">
            <input class="form-check-input" type="radio" name="tipMesaje" id="new" [(ngModel)]="tip" [value]="true" />
            <label class="form-check-label" for="new">necitite</label>
        </div>
        <button type="button" class="btn btn-sm btn-link me-3" title="Modifica setarile" (click)="setViewed('all')">
            <i class="fal fa-lg fa-cog"></i>
        </button>
    </div>
    <hr class="mt-auto mb-1" />

    <div class="list-group h-100" style="overflow: auto; max-height: 90vh;">
        <button type="button" class="list-group-item list-group-item-action" [ngClass]="getType(item)"
            *ngFor="let item of notify.getNotifications(tip)" (click)="setViewed(item)">
            <div class="fw-bold">
                <i class="fal fa-1x fa-fw" [title]="item.tip" [ngClass]="getIcon(item)"></i> {{item.nume}}
                <button type="button" class="btn btn-link btn-sm" (click)="notify.action(item)">
                    <i class="fal fa-ellipsis-h-alt" [title]="item.actiune"></i>
                </button>
                <small>
                    <button type="button" class="btn-close float-end" aria-label="Close" (click)="setViewed(item, true)"></button>
                </small>
            </div>
            <div class="text-truncate" [title]="item.descriere">{{item.descriere}}</div>
        </button>
    </div>

    <hr class="mt-auto mb-1" />
    <button type="button" class="list-group-item list-group-item-action border-0 text-center" (click)="setViewed('all')">
        <i class="fal fa-1x fa-comment-dots"></i>&nbsp;Vizualizare toate
    </button>
</div>