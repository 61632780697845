import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { mergeMap } from 'rxjs/operators';
import {
    UserService, ReportService, DataService, AppSettings, Query,
    NotificationService, DialogService, RoutingService, StyleManager,

} from 'core';
import { HelpContainer } from '../help/navbar.helpContainer';
import { NavbarNotificari } from './navbar.notificari';
import { FeedbackModal } from './navbar.feedback';

@Component({
    selector: 'navbar', templateUrl: './navbar.html', standalone: false,
    styles: ['.no-arrow.dropdown-toggle::after {display:none;}']
})
export class Navbar implements OnInit {
    constructor(private router: Router, private http: HttpClient,
        protected report: ReportService, protected style: StyleManager,
        private data: DataService, private user: UserService,
        private routing: RoutingService, private settings: AppSettings,
        private dialog: DialogService, protected notify: NotificationService,
        private offcanvasService: NgbOffcanvas) {

        routing.onRouteChange.pipe(
            mergeMap(route => route.data),
        ).subscribe((data: { [name: string]: any }) => {
            routing.setTitle(data.title);
            this.isConfigReadonly = data.isConfigReadOnly;
            this.hierarchyId = data.access;
            this.titleIcon = data.icon;
        });
        user.onLoginComplete.subscribe(currentUser => {
            if (currentUser) {
                let attempts = 0;
                var interval = setInterval(() => {
                    if (attempts >= 500 && interval) { clearInterval(interval) }
                    if (settings.loaded) {
                        clearInterval(interval);
                        interval = null;
                        this.getSuportOnline();
                    } else { attempts++ }
                }, 500)
            }
        });
    }

    protected titleIcon: string;

    protected isConfigReadonly: boolean;
    protected collapseUserNav: boolean = true;
    protected collapsePrintOp: boolean = true;
    protected collapseFavorites: boolean = true;

    private hierarchyId: string = "";
    private suportOnlineParams: string = "";
    protected get suportUrl() { return `http://www.indecosoft.ro/cerc/index.php${this.suportOnlineParams}` }

    protected get title() { return this.routing.title }
    protected get isBusy(): boolean { return this.http['isBusy'] }
    protected get userName() { return this.user.identity.name || this.user.identity.userName }

    protected isHelpOpen: boolean;
    protected isFullScreenActive: boolean;

    ngOnInit() { }

    protected navigateTo(route) { this.router.navigate([route]) }
    protected reportIcon(format: string) { return this.report.getFormatIcon(format) }
    protected setReportFormat(format: string) {
        this.report.format = format;
        this.collapsePrintOp = true;
    }
    protected logOut() {
        this.user.logOutNew();
        this.settings.loaded = false;
    }

    protected get providerClass() {
        switch (this.user.identity.provider) {
            case 'fab microsoft':
            case 'fab activeDirectory':
                return "fab fa-windows";
            case 'google': return "fab fa-google-plus";
            case 'facebook': return "fab fa-facebok";
            case 'yahoo': return "fab fa-yahoo";
            default: return `fa-light fa-${(this.user.identity.provider === 'Indeco' ? 'user' : this.user.identity.provider)}`;
        }
    }

    getFavorites() {
        // this.config.get(Config.favorites$Administraresistem$Administraresistem).then(fav => this.favorites = fav)
    }
    protected getSuportOnline() {
        //Query.user.getDateForSuport()
        this.data.executeQueryNew(Query.user.getUserBtn()).then(data => {
            if (data.length > 0) {
                // this.suportOnlineParams = `?ref=${data[0].cui}&ref2=${data[0].nume}`;
                // if (data[0].fullName) { this.suportOnlineParams += `&nume=${data[0].fullName}` }
                // if (data[0].telefon) { this.suportOnlineParams += `&tel=${data[0].telefon}` }
                // if (data[0].regiune) { this.suportOnlineParams += `&jud=${data[0].regiune}` }
                // if (data[0].localitate) { this.suportOnlineParams += `&loc=${data[0].localitate}` }
            }
        })
    }

    protected openNotificare() {
        this.offcanvasService.open(NavbarNotificari, { position: 'end' });
    }
    protected openHelp(evt: Event) {
        // window.open(this.router.createUrlTree([States.help.pagini, { id: window.btoa(this.hierarchyId) }]).toString(), '_blank');
        const ref = this.offcanvasService.open(HelpContainer, { position: 'end' });
        ref.componentInstance.hierarchyId = this.hierarchyId;
        ref.componentInstance.pos = 'end';
        ref.componentInstance.changePos = (position: string) => {
            ref['_panelCmptRef'].instance.position = position
        };
        ref.componentInstance.resize = (size: string) => {
            ref['_panelCmptRef'].instance._elRef.nativeElement.classList.remove("w-25", "w-50", "w-75", "w-100");
            if (size) { ref['_panelCmptRef'].instance._elRef.nativeElement.classList.add(size) }
        };
        this.collapseUserNav = true;
    }
    protected openFeedback() {
        this.dialog.custom(FeedbackModal, {}, { size: 'lg' });
        this.collapseUserNav = true;
    }

    @HostListener('window:resize', ['$event']) onfullscreenchangeResize(e) {
        this.isFullScreenActive = window.innerHeight === screen.height
    }
    @HostListener('document:fullscreenchange', ['$event']) onfullscreenchange(e) {
        this.isFullScreenActive = !!(document as Document).fullscreenElement
    }
    protected toggleFullScreen() {
        this.isFullScreenActive ? this.closeFullScreen() : this.openFullScreen();
        this.collapseUserNav = true;
    }
    private openFullScreen() {
        const elem = document.documentElement;
        if (elem.requestFullscreen) { elem.requestFullscreen({ navigationUI: 'show' }).then(() => this.isFullScreenActive = true) }
        else if (elem['mozRequestFullScreen']) { elem['mozRequestFullScreen']() } /* Firefox */
        else if (elem['webkitRequestFullscreen']) { elem['webkitRequestFullscreen']() } /* Chrome, Safari and Opera */
        else if (elem['msRequestFullscreen']) { elem['msRequestFullscreen']() } /* IE/Edge */
    }
    private closeFullScreen() {
        if (document.exitFullscreen) { document.exitFullscreen().then(() => this.isFullScreenActive = false) }
        else if (document['mozCancelFullScreen']) { document['mozCancelFullScreen']() } /* Firefox */
        else if (document['webkitExitFullscreen']) { document['webkitExitFullscreen']() } /* Chrome, Safari and Opera */
        else if (document['msExitFullscreen']) { document['msExitFullscreen']() } /* IE/Edge */
    }
}