import {
    Component, OnInit, Input, ViewChild,
    OnChanges, SimpleChanges, ElementRef
} from "@angular/core";
import { DataService, Query } from "core";

@Component({
    selector: "help-content", standalone: true,
    inputs: ['idResursa', 'hierarchyId', 'tipAfisare'],
    template: `<div id="content" class="border h-100 p-2" #content></div>`,
})
export class HelpContent implements OnInit, OnChanges {
    constructor(private data: DataService) { }

    @Input() idResursa: number;
    @Input() hierarchyId: string;
    @Input() tipAfisare: 'operare' | 'page' | 'flow';

    title: string = 'Help center';
    @ViewChild('content') protected content: ElementRef<HTMLElement>;

    ngOnInit() { }
    ngOnChanges(changes: SimpleChanges): void {
        var hasChange = false;
        if (changes.hierarchyId && changes.hierarchyId.currentValue != changes.hierarchyId.previousValue) {
            this.hierarchyId = changes.hierarchyId.currentValue;
            hasChange = true;
        }
        if (changes.idResursa && changes.idResursa.currentValue != changes.idResursa.previousValue) {
            this.idResursa = changes.idResursa.currentValue;
            hasChange = true;
        }
        if (hasChange) { this.getContent(this.idResursa, this.hierarchyId) }
    }

    private getContent(id: number, hierarchy?: string) {
        // this.data.executeQueryNew(Query.admin.getHelp(this.tipAfisare, id, hierarchy)).then(data => {
        //     if (!data.length) {
        //         // this.titleOut.emit('Help center');
        //         this.content.nativeElement.innerHTML = '<p class="text-center">Pentru aceasta pagina nu exista continut.</p>'
        //     } else {
        //         // this.titleOut.emit(data[0].titlu);
        //         this.title = data[0].titlu;
        //         this.content.nativeElement.innerHTML = data[0].continut;
        //     }
        // })
    }

    back() {

    }
}